import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';

import { useTabContext, useAppModeContext, useAuth, useConfig } from '@worklist-2/core/src';

import { getItemPageUrl } from '@rs-ui/components/SearchResults/SearchResultItem';
import SearchDataSetV2 from '@rs-ui/components/GlobalSearch/GlobalSearchV2/AutoSuggestDialogV2/SearchDataSetV2/SearchDataSetV2';

import searchApps from '../../../../../../../utils/marketplace/searchApps';
import EmergencySearch from '../EmergencySearch';
import styles from './AutoSuggestDialogV2.module.scss';

const AutoSuggestDialogV2 = ({
	value,
	data,
	closeSearchDialog,
	setTabsValue,
	openArticlesSearchResult,
	loggedInUserId,
	isLoadingUserId,
	onGoToUser,
	handleSaveRecentlyViewed,
	setParentData,
	additionalSearchParams,
	select,
	setIsClickAwayDisabled,
	setEmergencySearchOpen,
	showEmergencySearch,
}) => {
	const navigate = useNavigate();
	const { placeholder } = useTabContext();
	const { isWorklistMode, isHelpCenterMode } = useAppModeContext();
	const appModeIsWorklist = isWorklistMode();
	const appModeIsHelpCenter = isHelpCenterMode();
	const __config = useConfig();
	const { t } = useTranslation('Search');
	const { selectedLanguage } = useAuth();

	const getClickHandler = item => {
		const url = getItemPageUrl(item, loggedInUserId, appModeIsWorklist || appModeIsHelpCenter, selectedLanguage);
		if (url || item.resourceType === 'Practitioner')
			return async () => {
				handleSaveRecentlyViewed(item);
				if (url) {
					navigate(url);
				} else {
					await onGoToUser(item);
				}
				closeSearchDialog();
			};
		return undefined;
	};

	const onViewMore = () => {
		closeSearchDialog();
		if (select.length === 1 && select[0].label === 'Marketplace') {
			navigate(`/marketplace/appdirectory?name=${value}`);
			if (setParentData) {
				const searchParams = `?name=${value}${
					additionalSearchParams?.category ? `&categories=${additionalSearchParams.category}` : ''
				}${additionalSearchParams?.vendor ? `&author=${additionalSearchParams.vendor}` : ''}`;

				searchApps({
					config: __config,
					queryParamString: searchParams,
				}).then(res => {
					setParentData(res);
				});
			}
		} else if (select.length === 1 && select[0].label === 'Help') {
			openArticlesSearchResult();
		} else {
			setTabsValue(value);
		}
	};

	return (
		<div className={styles.autoSuggestDialog}>
			{showEmergencySearch && (
				<div className={styles.emergencySearch}>
					<EmergencySearch setEmergencySearchOpen={setEmergencySearchOpen} t={t} />
				</div>
			)}
			<div className={styles.main}>
				{data?.length > 0 && (
					<SearchDataSetV2
						isAutoSuggest
						data={data}
						getOnClick={getClickHandler}
						isLoadingUserId={isLoadingUserId}
						select={select}
						setIsClickAwayDisabled={setIsClickAwayDisabled}
						onCloseDialog={closeSearchDialog}
					/>
				)}
			</div>

			{data && (data.length > 5 || (data.length > 1 && placeholder === 'All')) && (
				<div className={styles.footer} onClick={onViewMore}>
					<span className={styles.footerText}>{t('VIEW MORE')}</span>
				</div>
			)}
		</div>
	);
};

AutoSuggestDialogV2.propTypes = {
	/*
	 * The search query
	 */
	value: PropTypes.string.isRequired,
	/*
	 * The data to show in the dialog
	 */
	data: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	/*
	 * Dialog event handlers
	 */
	closeSearchDialog: PropTypes.func,
	setSearchText: PropTypes.func,
	/*
	 * Dialog header
	 */
	showDialogHeader: PropTypes.bool,
	/*
	 * Function for updating parent component state, if necessary
	 */
	setParentData: PropTypes.func,
	/*
	 * Additional Search Parameters
	 */
	additionalSearchParams: PropTypes.object,
};

export default AutoSuggestDialogV2;
