/* Generated automagically by FhirStarter - DO NOT EDIT */
import React, { useMemo, useState, useEffect, createRef, useCallback } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { ColumnFilter } from '../../../ui/src/components';
import { customHeadLabelRenderer } from '../../../ui/src/components/Grid/ColumnFilter';
import { fhirEndpoints, useSearchScope, getColumnMapping, getColumnValueSet, scopeMapping } from '..';
import { columnGroupings } from '../utils';
import DataGridHeader from '@worklist-2/ui/src/components/DataGrid/DataGridHeader';
import { isWholeDay, translateDate } from '@rs-ui/components/utils/dateUtils';

import { useConfig, useAuth, searchScopes, useFhirDataLoader } from '@worklist-2/core/src';
import { CUSTOM_FILTER_OPTIONS_LIST } from '@rs-ui/helpers/constants';

import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

/**
 * @param  {Object} options
 * @param  {Array} options.rows I do not know what this is for
 * @param  {Array} options.columns A list of strings representing column names to be built into a list
 * @param  {Object} options.filterFns A collection of functions to be applied to columns upon that column's filter being changed
 * @param  {Function} options.filtersFn Callback to be called from within each filter type's filter function
 * @param  {Object} options.filters Currently set filters
 */

const useColumnList = ({
	rows,
	columns = [],
	filters,
	setFilters,
	scope,
	sortOrder,
	setSort,
	isSingleSort,
	columnSizing,
	enableCustomStudyPriority,
	gridCustomHeader,
}) => {
	const __searchScopeContext = useSearchScope();
	const [ref, setRef] = useState({});
	const [columnArray, setColumnArray] = useState([]);
	const [columnsList, setColumnsList] = useState(columns || columnGroupings.default);
	const [managingOrgId, setManagingOrgId] = useState(null);

	const organizationLoader = useFhirDataLoader({ scope: searchScopes.organization });
	const { userRoleForOrganizations, userPrivilegesForOrganizations, userAssociatedWithOnlyOneOrg } = useAuth();

	const __config = useConfig();

	const i18nfile = scope?.replace('search', '');

	const { t, ready } = useTranslation(i18nfile);

	const scopeEndpoint = scope ? scopeMapping[scope] : __searchScopeContext.endpoint;

	// FEATURE FLAGS
	const crossGetPrivilegeOnDemand = useBooleanFlagValue('cross-get-privilege-on-demand');

	// In case the login user belongs to only one organziation, use study statuses of this organization for worklist grid - column header
	useEffect(() => {
		// set managingOrgId
		if (crossGetPrivilegeOnDemand) {
			const managingOrgId = userAssociatedWithOnlyOneOrg
				? userPrivilegesForOrganizations?.current[0]?.organizationId
				: filters.find(f => f.searchParameter == 'internalManagingOrganizationID')?.values;
			setManagingOrgId(managingOrgId);
		} else {
			const managingOrgId =
				userRoleForOrganizations.length == 1
					? userRoleForOrganizations[0].organizationId
					: filters.find(f => f.searchParameter == 'internalManagingOrganizationID')?.values;
			setManagingOrgId(managingOrgId);
		}
	}, [
		crossGetPrivilegeOnDemand,
		crossGetPrivilegeOnDemand ? userAssociatedWithOnlyOneOrg : userRoleForOrganizations,
	]);

	const { data: managingOrgIdsFromAPI } = useQuery({
		queryKey: ['fetchMasterOrganizations'],
		queryFn: async () => {
			const managingOrgs = await organizationLoader.load({
				partOfMissing: true,
				getmissingorgs: 'masters',
				isReferring: false,
				summary: true,
				count: 500,
			});
			const managingOrgIds = managingOrgs?.map(entry => entry?.id).join(',');
			return managingOrgIds;
		},
		refetchOnWindowFocus: false,
		enabled: crossGetPrivilegeOnDemand,
	});

	const managingOrgIds = crossGetPrivilegeOnDemand
		? managingOrgIdsFromAPI
		: userRoleForOrganizations.map(u => u.organizationId).join(',');

	const { data: studyStatusData } = useQuery({
		queryKey: ['studyStatus' + managingOrgId],
		queryFn: async () => {
			if (managingOrgId) {
				let result = await axios.get(
					`${__config.data_sources.fhir}/studyStatus?organization=${managingOrgId}&_sort=statusValue&active=true`
				);
				return result.data.entry.map(e => ({ code: e.resource.id, display: e.resource.status }));
			} else {
				let result = await axios.get(`${__config.data_sources.fhir}/valueSet?name:exact=status&_sort=display`);
				return result?.data?.entry?.[0].resource.compose.include[0].concept.map(r => r);
			}
		},
		refetchOnWindowFocus: false, // false so this won't get called repeatedly
	});

	const { data: procedureCodeData } = useQuery({
		queryKey: ['procedureCode' + managingOrgIds],
		queryFn: async () => {
			if (managingOrgIds) {
				let result = await axios.get(
					`${__config.data_sources.fhir}/ProcedureCode?organizationId=${managingOrgIds}&page=1&_count=500&_sort=code`
				);
				return result.data.entry.map(e => ({ code: e.resource.code, display: e.resource.display }));
			} else {
				return [];
			}
		},
		refetchOnWindowFocus: false, // false so this won't get called repeatedly
	});

	let customPriorityData;
	if (enableCustomStudyPriority && managingOrgId !== null) {
		const { data: priorityData } = useQuery({
			queryKey: ['Priority' + managingOrgId],
			queryFn: async () => {
				if (managingOrgId) {
					let result = await axios.get(
						`${__config.data_sources.fhir}/Priority?organization=${managingOrgId}&_sort=priority`
					);
					return result.data.entry.map(e => ({ code: e.resource.id, display: e.resource.display }));
				} else {
					let result = await axios.get(
						`${__config.data_sources.fhir}/valueSet?name:exact=priority&_sort=display`
					);
					return result?.data?.entry?.[0].resource.compose.include[0].concept.map(r => r);
				}
			},
		});
		customPriorityData = priorityData;
	}

	useEffect(() => {
		if (!!columns && !_.isEmpty(columns)) {
			setColumnsList(
				_.isEmpty(_.pickBy(columns, column => !_.isNil(column))) ? columnGroupings.default : columns
			);
		}
	}, [columns]);

	const columnMapping = useMemo(() => {
		return getColumnMapping(scopeEndpoint) ?? getColumnMapping(fhirEndpoints.imagingStudyWorklist);
	}, [scopeEndpoint]);

	// filter functions
	// handles adding and removing capsules using filtering logic
	const filtersFunc = useCallback(
		(columnIdentity, values, displayValue, allMenuItems, searchParam) => {
			const { label } = columnMapping[columnIdentity];
			let flattenValues = _.isArray(values) ? _.flatten(values).filter(String) : values;
			const displayVal = getDisplayValue(columnMapping[columnIdentity].filterType, values, displayValue);
			if (columnMapping[columnIdentity].findById) {
				flattenValues = columnMapping[columnIdentity].customFilterOptions
					.filter(item => flattenValues.includes(item?.display))
					.map(item => item.id);
			}

			setFilters(prevFilters => {
				const filterIdx = _.findIndex(prevFilters, elem => elem.columnIdentity === columnIdentity);
				let newFilters;

				const filter = {
					displayValue: _.isArray(displayVal) ? _.flatten(displayVal).filter(String) : displayVal,
					label,
					columnIdentity,
					// Remove empty string values
					values: flattenValues,
					capsuleMenuItems: allMenuItems ?? [],
					// Use the searchParam that is assigned from a child component for filter instead of the searchParam configured in
					// ImagingStudyWorklistMapping (Managing Organization column is an example)
					searchParameter: searchParam || columnMapping[columnIdentity].searchParameter,
					searchValueSet: columnMapping[columnIdentity].searchValueSet,
					filterType: columnMapping[columnIdentity].filterType,
					toolTip: columnMapping[columnIdentity].toolTip,
					valueSetExtraParam: columnMapping[columnIdentity].valueSetExtraParam,
				};
				if (CUSTOM_FILTER_OPTIONS_LIST.includes(columnIdentity)) {
					filter.customFilterOptions = columnMapping[columnIdentity].customFilterOptions;
				}

				if (filterIdx >= 0) {
					newFilters = _.toArray(
						_.pickBy(
							// replace the value
							_.concat(_.slice(prevFilters, 0, filterIdx), filter, _.slice(prevFilters, filterIdx + 1)),
							// get rid of any capsules whose value array is empty
							elem => (_.isArray(elem.values) ? !_.isEmpty(elem.values) : true)
						)
					);
				} else {
					newFilters = _.concat(prevFilters, filter);
				}
				// perform 3 types of check: date, string, and array
				const filteredNewFilters = _.filter(_.compact(newFilters), elem =>
					_.isDate(elem.values) ? moment(elem.values).isValid() : elem.values?.length > 0
				);

				// if filters didn't change, no need to refetch data
				if (_.isEqual(filteredNewFilters, prevFilters)) {
					return prevFilters;
				}

				return filteredNewFilters;
			});
		},
		[setFilters]
	);

	const getDisplayValue = (filterType, values, displayValue) => {
		if (filterType === 'date-range') {
			return _.isArray(values) ? values.map(date => translateDate(t, date, !isWholeDay(values))) : t(values);
		} else {
			return displayValue ?? values;
		}
	};

	// suggest
	const suggestFn = useCallback((filterValue, displayValue, filter, searchParam) => {
		filtersFunc(filter, filterValue, displayValue, null, searchParam);
	}, []);

	// text search
	const textSearchFn = useCallback((filterValue, displayValue, filter) => {
		filtersFunc(filter, filterValue, displayValue);
	}, []);

	// multi-select
	const multiSelectFn = useCallback((filterValue, displayValue, filter) => {
		filtersFunc(filter, [filterValue.selectedOptions], displayValue, [filterValue.fullOptionsList]);
	}, []);

	// single-select
	const singleSelectFn = useCallback((filterValue, displayValue, filter) => {
		filtersFunc(filter, [filterValue.selectedOptions], [displayValue], [filterValue.fullOptionsList]);
	}, []);

	// date-time
	const dateTimeFn = useCallback((filterValue, displayValue, filter) => {
		filtersFunc(filter, filterValue, displayValue);
	}, []);

	// date-range
	const pillOptions = ['Last 24 hrs', 'Today', 'Tomorrow', 'Week', 'Month', 'Last 7 days', 'Last 30 days'];
	const dateRangeFn = useCallback((filterValue, displayValue, filter) => {
		let dateValues = [];
		if (filterValue?.length == 2 || pillOptions.includes(filterValue)) {
			dateValues = filterValue;
		}

		filtersFunc(
			filter, // the name of the filter which is uset to set the label in pill ( example taskStart => Task Start: )
			filterValue,
			dateValues, // the value that is sent into pill
			[filterValue.fullOptionsList]
		);
	}, []);

	// Function to set Sort Options/Order
	const setSortOrder = sortOrder => {
		// Destructuring and Passing new Array
		setSort && setSort([...sortOrder]);
	};

	const valueSet = useMemo(() => getColumnValueSet(columnsList, scopeEndpoint), [scopeEndpoint]);

	const createFieldRef = mapping => {
		let refArray = {};
		_.forEach(_.keys(mapping), key => {
			let forceShowRef = createRef();
			let fieldRef = createRef();
			refArray[key] = _.fromPairs([
				['forceShowRef', forceShowRef],
				['fieldRef', fieldRef],
			]);
		});

		return refArray;
	};

	const getItemListForMultiSelect = column => {
		switch (column) {
			case 'studyStatus':
				return studyStatusData || [];
			case 'priority':
				return enableCustomStudyPriority ? customPriorityData || [] : valueSet[column];
			case 'procedureCode':
				return procedureCodeData || [];
			default:
				return valueSet[column];
		}
	};

	useEffect(() => {
		setRef(createFieldRef(columnMapping));
	}, [columnMapping]);

	useEffect(() => {
		if (!_.isEmpty(ref)) {
			let tempArray = [];

			for (let column of columnsList) {
				let func,
					style,
					mapping = columnMapping[column],
					itemList = [],
					preSelectedValues = [];
				if (!mapping) continue;
				switch (mapping?.filterType) {
					case 'suggest':
						itemList = _.uniqBy(
							_.map(rows, r => _.fromPairs([[column, r[column]]])),
							column
						);
						func = suggestFn;
						preSelectedValues = '';
						break;
					case 'single-select':
					case 'checkbox-single-select':
						preSelectedValues = '';
						itemList = valueSet[column];
						func = singleSelectFn;
						break;
					case 'multi-select':
					case 'checkbox-multi-select':
						itemList = getItemListForMultiSelect(column);
						func = multiSelectFn;
						break;
					case 'text-search':
						func = textSearchFn;
						preSelectedValues = '';
						break;
					case 'date-time':
						func = dateTimeFn;
						break;
					case 'date-range':
						func = dateRangeFn;
						break;
					default:
						break;
				}

				let filterItem = filters.find(element => element.columnIdentity === column);

				let filterValue = '';

				const multiFilterTypes = ['multi-select', 'checkbox-multi-select'];

				if (filterItem) {
					if (filterItem?.filterType === 'date-range') {
						filterValue = filterItem?.values;
					} else if (_.isArray(filterItem?.displayValue)) {
						filterValue = multiFilterTypes.includes(mapping?.filterType)
							? _.join(filterItem?.displayValue, '|')
							: filterItem?.displayValue[0];
					} else {
						filterValue = filterItem.displayValue;
					}

					if (!_.isEmpty(ref[column].fieldRef.current)) {
						// update value of filter
						ref[column].fieldRef.current.value = filterValue;
					}
					preSelectedValues = filterValue;
				}
				try {
					if (
						!!columnMapping[column].options.display &&
						columnMapping[column].options.display === 'excluded'
					) {
						let newColumn = {
							name: column,
							label: t(mapping?.label),
							options: columnMapping[column].options,

							// new grid
							header: mapping?.label,
							cell: () => null,
							meta: {
								visible: columnMapping[column]?.options?.display,
							},
						};
						tempArray.push(newColumn);
					} else {
						// temporary logic fix to avoid the blank label after selecting in multiselect component, Shawn is working on it
						const displayValue =
							filterValue && mapping && mapping.filterType !== 'multi-select'
								? filterValue
								: mapping?.displayValue;

						const columnWidth = columnSizing && columnSizing[column] >= 200 ? columnSizing[column] : 200;

						let newColumn = {
							name: column,
							label: t(mapping?.label),
							fieldRef: ref[column].fieldRef,
							filterType: mapping?.filterType,
							options: {
								...columnMapping[column].options,
								customHeadLabelRender: (colData, ...args) => {
									return customHeadLabelRenderer(
										...args,
										<ColumnFilter
											fieldRef={ref[column].fieldRef}
											itemList={itemList}
											label={t(mapping?.label)}
											searchId={
												mapping?.searchValueSet
													? mapping.searchValueSet
													: mapping?.searchParameter
											}
											displayValue={displayValue}
											capitalizeOptions={mapping?.capitalizeOptions}
											onSelected={(val, displayVal) => {
												func(val, displayVal, column);
											}}
											filterType={mapping?.filterType}
											forceShowRef={ref[column].forceShowRef}
											suggestScope={mapping.suggestScope}
											preSelectedValues={preSelectedValues}
											customFilterOptions={mapping?.customFilterOptions}
											valueSetExtraParam={mapping?.valueSetExtraParam}
										/>,
										ref[column].forceShowRef,
										style,
										func ? true : false
									);
								},
							},
							// new grid
							// columnWidth is set to the dropdown list width -> need set min width to 200 to avoid dropdown list width broken
							accessorFn: mapping?.accessorFn ? mapping.accessorFn : mapping?.getDisplay,
							header:
								gridCustomHeader && mapping?.customHeader ? (
									mapping.customHeader
								) : (
									<DataGridHeader
										{...mapping}
										label={t(mapping.label)}
										column={column}
										setSortOrder={setSortOrder}
										sortOrder={sortOrder}
										isSingleSort={isSingleSort}
										func={func}
										itemList={itemList}
										preSelectedValues={preSelectedValues}
										customFilterOptions={mapping?.customFilterOptions}
										columnWidth={`${columnWidth}px`}
										valueSetExtraParam={mapping?.valueSetExtraParam}
									/>
								),
							cell: mapping?.cell ? mapping?.cell : ``,
							meta: { draggable: mapping?.options?.draggable },
						};
						tempArray.push(newColumn);
					}
				} catch (error) {
					console.error(`Error: ${error} - current column: ${column}`);
					throw error;
				}
			}
			setColumnArray(tempArray);
		}
	}, [
		ref,
		filters,
		columnMapping,
		columnsList,
		studyStatusData,
		procedureCodeData,
		ready,
		columnSizing,
		gridCustomHeader,
		customPriorityData,
	]);

	return { columnArray, filtersFunc };
};

export default useColumnList;
