// @flow

import { type IPatientLink } from '@rs-ui/views/PatientInformationView/utils/declarations';
import { fhirExtensionUrls, getUserFullName } from '@rs-core/fhir';
import { FILE_ICON } from './constants';
import { v4 as uuidv4 } from 'uuid';
import { trimEnd } from 'lodash';

export const getPatientExternalId = patient =>
	patient?.identifier?.find(item => item.type?.coding?.[0].code === 'MR')?.value || '';

export const findTelecomIndex = (telecomArray, system, use) =>
	telecomArray?.findIndex(item => item.system === system && (!use || item.use === use));

export const findExtensionIndex = (extensionArray, url) => extensionArray?.findIndex(element => element.url === url);

export const showReadableFullName = displayName => displayName?.replace('^', ', ').replaceAll('^', ' ');

export const isEmergencyContactValid = contact => {
	const email = contact?.telecom?.[0]?.value;
	const phone = contact?.telecom?.[1]?.value;
	const contactName = contact?.name?.text;

	return contactName && (email || phone);
};

export const prepareRelationshipOptions = (relationshipList, selectedCode = 'UNK') => {
	const selectedOption = relationshipList?.find(item => item?.code === selectedCode);

	if (selectedOption) {
		const filteredOptions = relationshipList?.filter(item => item?.code !== selectedCode);
		return [selectedOption, ...filteredOptions];
	}

	return relationshipList;
};

export const parseDocument = doc => {
	return {
		id: doc.id,
		fileName: doc.content[0].attachment.title,
		description: doc.description?.replaceAll('<br>', ' ').replaceAll('?', ''),
		extension: FILE_ICON[doc?.content?.[0]?.attachment?.contentType || 'application/pdf'],
	};
};

// Using Patient resource
export const findDifferentFields = array => {
	const fieldNames = ['Patient Name', 'Issuer of Patient ID', 'Patient ID', 'Birth Date', 'Gender'];
	const differentFields = [];

	for (const fieldName of fieldNames) {
		const valueSet = new Set();

		for (const item of array) {
			let value;

			// eslint-disable-next-line default-case
			switch (fieldName) {
				case 'Patient Name':
					value = item?.name?.[0].text;
					break;
				case 'Birth Date':
					value = item?.birthDate;
					break;
				case 'Gender':
					value = item.gender;
					break;
				case 'Patient ID':
					value = item.id;
					break;
				case 'Issuer of Patient ID':
					value = item.managingOrganization.display;
					break;
			}

			valueSet.add(value);

			if (valueSet.size > 1) {
				differentFields.push(fieldName);
				break;
			}
		}
	}

	return differentFields;
};

export const createLinkPayload = (currentPatient, patientToLink, existingLinks) => {
	const patientToLinkIssuer = patientToLink?.patient?.extension?.find(
		ext => ext.url === fhirExtensionUrls.organization.issuer
	);

	const newLink: IPatientLink = {
		other: {
			display: patientToLink?.patient?.name?.[0]?.text,
			id: patientToLink?.patientInternalID,
			reference: `Patient/${patientToLink?.patientID}`,
			identifier: patientToLink?.patient?.identifier.find(
				item =>
					item.type?.coding?.[0].system === 'http://hl7.org/fhir/ValueSet/identifier-type' &&
					item.type?.coding?.[0].code === 'MR'
			),
			extension: [
				{
					url: fhirExtensionUrls.common.managingOrganization,
					valueReference: patientToLink?.patient?.managingOrganization,
				},
				{
					url: fhirExtensionUrls.common.gender,
					valueString: patientToLink?.patient.gender,
				},
				{
					url: fhirExtensionUrls.common.birthdate,
					valueString: patientToLink?.birthDate,
				},
				{
					url: fhirExtensionUrls.organization.issuer,
					valueString: patientToLinkIssuer ? patientToLinkIssuer.valueReference.display : '',
				},
			],
		},
		type: 'seealso',
	};

	return {
		...currentPatient,
		link: existingLinks.length ? [newLink, ...existingLinks] : [newLink],
	};
};

export const splitLinkedPatientsOnParts = arr => arr.map(patient => [patient]);

export function isEmailChanged(initialPayload: any[] = [], newPayload: any[] = []): boolean {
	const initialEmails = initialPayload.filter(item => item.system === 'email');
	const newEmails = newPayload.filter(item => item.system === 'email');

	if (initialEmails.length !== 1 || newEmails.length !== 1) {
		return false;
	}

	return initialEmails[0].value !== newEmails[0].value;
}

export const extractGuarantorInfo = (entry, selfGuarantor) => {
	const { resource } = entry;

	if (!resource || resource.resourceType !== 'Account' || !resource.guarantor || resource.guarantor.length === 0) {
		return selfGuarantor;
	}
	const guarantorAssociatedParty = resource?.guarantor?.[0]?.party;
	const guarantorRelationship = guarantorAssociatedParty?.extension?.find(ext =>
		ext.url.includes('guarantorRelationship')
	);
	const phone = guarantorAssociatedParty?.extension?.find(ext => ext.url.includes(fhirExtensionUrls.account.phone));

	return {
		accountId: resource.id,
		guarantorId: guarantorAssociatedParty?.id,
		name: getUserFullName(guarantorAssociatedParty.display, true) || 'N/A',
		phoneNumber: phone ? phone.valueString : 'N/A',
		relationshipType: guarantorRelationship ? guarantorRelationship.valueCoding : selfGuarantor?.relationshipType,
		selfGuarantorInfo: selfGuarantor,
	};
};

export const transformContacts = contacts => {
	return contacts.map(contact => {
		const givenName = contact?.givenName ? contact.givenName?.trim().split(' ').join('^') : '';
		const text = `${contact.familyName?.trim() || ''}^${givenName}`.trim();
		const trimmedName = trimEnd(text, '^ ');
		const fhirName = {
			text: trimmedName,
			family: contact?.familyName?.trim() || '',
			given: contact?.givenName?.trim().split(' ') || '',
			prefix: [],
			suffix: [],
		};
		return {
			name: fhirName,
			telecom: [
				{
					system: 'phone',
					value: contact.phone,
				},
				{
					system: 'email',
					value: contact.email,
				},
			],
			extension: [
				{
					url: 'EmergencyContactId',
					valueString: contact.id,
				},
				{
					url: 'change-request-status',
					valueString: 'Pending',
				},
			],
		};
	});
};

export const reverseTransformContacts = transformedContacts => {
	return transformedContacts.map(contact => {
		const nameParts = getUserFullName(contact?.name, true).split(' ');
		const [familyName, ...givenNames] = nameParts;
		const givenName = givenNames?.join(' ');
		const email = contact?.telecom?.find(t => t.system === 'email')?.value || '';
		const phone = contact?.telecom?.find(t => t.system === 'phone')?.value || '';
		const id = contact?.extension?.find(ext => ext.url === 'EmergencyContactId')?.valueString || uuidv4();

		return {
			familyName,
			givenName,
			email,
			phone,
			id,
		};
	});
};
