// #region DATA
export const VIEW_TYPES = {
	day: {
		id: 'day',
		label: 'Today',
	},
	week: {
		id: 'week',
		label: 'Weekly',
	},
	month: {
		id: 'month',
		label: 'Monthly',
	},
};

export const HIGHLIGHT_MODE = {
	available: 'available',
	suggested: 'suggested',
};

export const VISIBLE_HOURS_OPTIONS = {
	eightHours: {
		id: '8Hours',
		label: '8 Hours',
		value: 8,
	},
	twelveHours: {
		id: '12Hours',
		label: '12 Hours',
		value: 12,
	},
	eightteenHours: {
		id: '18Hours',
		label: '18 Hours',
		value: 18,
	},
};

export const STATUS_TO_COLOR = {
	proposed: {
		active: '#FFC045',
		inactive: '#474755',
	},
	// scheduled
	pending: {
		active: '#FE9C7E',
		inactive: '#3C3735',
	},
	booked: {
		active: '#93B7FF',
		inactive: '#383A43',
	},
	arrived: {
		active: '#48C496',
		inactive: '#38433F',
	},
	// ready for scan
	'checked-in': {
		active: '#25BDED',
		inactive: '#273E4E',
	},
	cancelled: {
		active: '#CF6697',
		inactive: '#353320',
	},
	noshow: {
		active: '#ED9C25',
		inactive: '#353320',
	},
	fulfilled: {
		active: '#3731CC',
		inactive: '#3C3735',
	},
};

export const DAYS_OF_WEEK_SHORT = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

export const DAYS_OF_WEEK_LONG = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

export const DAYS_OF_WEEK_NUMBER = DAYS_OF_WEEK_LONG.length;
// #endregion

// #region UI
export const SMALL_SCREEN_WIDTH = 385;

export const WIDE_SCREEN_WIDTH = 635;

export const FULL_SCREEN_EXPANDED_WIDTH = 'calc(100vw - 118px)';

export const DEFAULT_MEDIA_QUERY = '(max-width:1100px)';

export const DEFAULT_CALENDAR_ROWS_NUMBER = 96;

export const DEFAULT_PARKING_SLOTS_NUMBER = 11;

export const MINUTES_PER_ROW = 15;

export const ROW_HEIGHT_MIN_8_HOUR = 18;

export const ROW_HEIGHT_MIN_12_HOUR = 11.5;

export const DEFAULT_BORDER_WIDTH_COEF = 2;

export const MONTH_VIEW_HEADER_HEIGHT = 23;

export const MONTH_VIEW_EVENT_HEIGHT = 23;

export const CALENDAR_VIEWABLE_HOURS = 24;
// #endregion

// # region EVENT
export const EVENT_TYPE_APPOINTMENT = 'APPOINTMENT';

export const EVENT_TYPE_APPOINTMENT_RESERVE = 'APPOINTMENT_RESERVE';

export const EVENT_TYPE_MONTHEVENT = 'MONTHEVENT';

export const EVENT_TYPE_CARD_APPOINTMENT = 'CARD_APPOINTMENT';

export const EVENT_TYPE_ROW = 'ROW';

export const EVENT_TYPE_BLOCKED = 'BLOCKED';

export const RESERVE_COLOR_CODES = ['#20A8D4', '#217FDE', '#3631CC', '#3F63DA', '#1E6197', '#542999', '#634F93'];

export const CALENDAR_EVENT_INTERVAL = 5;

export const MIN_APPOINTMENT_DURATION_IN_MINS = 10;
// #endregion

// # region OTHER
export const PARKING_INDEX_MULTIPLY_COEF = 100000;

export const DEFAULT_PARKING_APPOINTMENT_STATUS = 'proposed';

export const COMPLETED_APPOINTMENT_STATUS = 'fulfilled';

export const DROP_ALLOWED_STUDY_STATUS = 'ORDERED';

export const DEFAULT_PARKING_APPOINTMENT_STATUS_COLOR = 'rgba(254, 156, 126)';

export const DEFAULT_OVERLAP_EVENTS_LIMIT = 5;

export const DEFAULT_CALENDARS_PER_VIEW = 1;
// #endregion
