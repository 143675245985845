import getQuarterTimeRange from '@worklist-2/ui/src/views/utils/getQuarterTimeRange';
import getLastQuarterTimeRange from './getLastQuarterTimeRange';

/**
 * Generate pill date
 * @param  {String} pillType - the string to be converted.
 * @return {Array}
 */
const generatePillDateFormat = pillType => {
	let startDateTime, endDateTime, quarterTime;
	const today = new Date();
	switch (pillType) {
		case 'Last 24 hrs':
			startDateTime = new Date(today.getTime() - 24 * 60 * 60 * 1000);
			endDateTime = today;
			break;
		case 'Today':
			startDateTime = new Date(today.getFullYear(), today.getMonth(), today.getDate());
			endDateTime = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 23, 59, 59);
			break;
		case 'Tomorrow':
			startDateTime = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
			endDateTime = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1, 23, 59, 59);
			break;
		case 'Last 7 days':
			startDateTime = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
			endDateTime = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1, 23, 59, 59);
			break;
		case 'Last 30 days':
			startDateTime = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 30);
			endDateTime = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1, 23, 59, 59);
			break;
		case 'This week':
		case 'Week':
			startDateTime = new Date(today.setDate(today.getDate() - today.getDay()));
			startDateTime.setHours(0, 0, 0);
			endDateTime = new Date(today.setDate(today.getDate() - today.getDay() + 6));
			endDateTime.setHours(23, 59, 59);
			break;
		case 'Last week':
			startDateTime = new Date(today.setDate(today.getDate() - today.getDay() - 7));
			startDateTime.setHours(0, 0, 0);
			endDateTime = new Date(today.setDate(today.getDate() - today.getDay() + 6));
			endDateTime.setHours(23, 59, 59);
			break;
		case 'This month':
		case 'Month':
			startDateTime = new Date(today.getFullYear(), today.getMonth(), 1);
			endDateTime = new Date(today.getFullYear(), today.getMonth() + 1, 0, 23, 59, 59);
			break;
		case 'Last month':
			startDateTime = new Date(today.getFullYear(), today.getMonth() - 1, 1);
			endDateTime = new Date(today.getFullYear(), today.getMonth(), 0, 23, 59, 59);
			break;
		case 'This year':
			startDateTime = new Date(today.getFullYear(), 0, 1);
			endDateTime = new Date(today.getFullYear() + 1, 0, 0, 23, 59, 59);
			break;
		case 'Last year':
			startDateTime = new Date(today.getFullYear() - 1, 0, 1);
			endDateTime = new Date(today.getFullYear(), 0, 0, 23, 59, 59);
			break;
		case 'This quarter':
			quarterTime = getQuarterTimeRange(today);

			startDateTime = quarterTime.start;
			endDateTime = quarterTime.end;
			endDateTime.setHours(23, 59, 59);
			break;
		case 'Last quarter':
			quarterTime = getLastQuarterTimeRange(today);

			startDateTime = quarterTime.start;
			endDateTime = quarterTime.end;
			endDateTime.setHours(23, 59, 59);
			break;
		default:
			break;
	}

	return startDateTime && endDateTime ? [startDateTime, endDateTime] : [];
};

export default generatePillDateFormat;
