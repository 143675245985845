const BROADCAST_EVENTS = {
	REQUEST_SYNCHRONIZE: 'REQUEST_SYNCHRONIZE',
	RECEIVE_SYNCHRONIZE: 'RECEIVE_SYNCHRONIZE',
	DRAG_SERIES_START: 'DRAG_SERIES_START',
	DRAG_SERIES_COMPLETE: 'DRAG_SERIES_COMPLETE',
	SERIES_LINKING_UPDATE: 'SERIES_LINKING_UPDATE',
	WINDOW_CLOSED: 'WINDOW_CLOSED',
	MEASUREMENTS_INIT: 'MEASUREMENTS_INIT',
	MEASUREMENTS_UPDATE: 'MEASUREMENTS_UPDATE',
	MEASUREMENT_ADD: 'MEASUREMENT_ADD',
	MEASUREMENT_COMPLETED: 'MEASUREMENTS_COMPLETED',
	MEASUREMENT_MODIFIED: 'MEASUREMENT_MODIFIED',
	MEASUREMENT_DELETE: 'MEASUREMENT_DELETE',
	MEASUREMENT_SELECTED: 'MEASUREMENT_SELECTED',
	MEASUREMENT_CHANGE_VISIBILITY: 'MEASUREMENT_CHANGE_VISIBILITY',
	NAVIGATE_TO_MEASUREMENT: 'NAVIGATE_TO_MEASUREMENT',
	KEY_IMAGES_UPDATE: 'KEY_IMAGES_UPDATE',
	ACTIVE_VIEWPORT_ID_UPDATE: 'ACTIVE_VIEWPORT_ID_UPDATE',
	SHADOW_ACTIVE_VIEWPORT_SET_STACK: 'SHADOW_ACTIVE_VIEWPORT_SET_STACK',
	SHADOW_ACTIVE_VIEWPORT_UPDATE_IMAGE_INDEX: 'SHADOW_ACTIVE_VIEWPORT_UPDATE_IMAGE_INDEX',
	SHADOW_ACTIVE_VIEWPORT_REQUEST_SYNCHRONIZE: 'SHADOW_ACTIVE_VIEWPORT_REQUEST_SYNCHRONIZE',
	SHADOW_ACTIVE_VIEWPORT_CAMERA_MODIFIED: 'SHADOW_ACTIVE_VIEWPORT_CAMERA_MODIFIED',
	PUSH_HISTORY_ITEM: 'PUSH_HISTORY_ITEM',
	IMAGE_UNDO: 'IMAGE_UNDO',
	IMAGE_RESET: 'IMAGE_RESET',
	ACTIVE_TOOL_UPDATE: 'ACTIVE_TOOL_UPDATE',
	WHEEL_SETTINGS_UPDATE: 'WHEEL_SETTINGS_UPDATE',
	SELECTED_HANGING_PROTOCOL_ID_UPDATE: 'SELECTED_HANGING_PROTOCOL_ID_UPDATE',
	SELECTED_HANGING_PROTOCOL_STAGE_UPDATE: 'SELECTED_HANGING_PROTOCOL_STAGE_UPDATE',
	LAYOUT_ITEMS_INITIAL_SYNC: 'LAYOUT_ITEMS_INITIAL_SYNC',
	LAYOUT_ITEMS_UPDATE: 'LAYOUT_ITEMS_UPDATE',
	LAYOUT_SIZE_UPDATE: 'LAYOUT_SIZE_UPDATE',
	TOGGLE_DOCUMENT_VIEWER: 'TOGGLE_DOCUMENT_VIEWER',
	ROUTE_CHANGED: 'ROUTE_CHANGED',
	URL_CHANGED: 'URL_CHANGED',
	PRIORS_NAVIGATION: 'PRIORS_NAVIGATION',
	STUDY_SERIES_UPDATE: 'STUDY_SERIES_UPDATE',
	DICTATION_DEVICE_ADDED: 'DICTATION_DEVICE_ADDED',
	STUDY_SIGNED: 'STUDY_SIGNED',
	NO_FURTHER_STUDIES: 'NO_FURTHER_STUDIES',
	EXTERNAL_REDIRECT: 'EXTERNAL_REDIRECT',
	USER_LOGOUT: 'USER_LOGOUT',
};

const LAYOUT_TYPE = {
	DEFAULT: 'DEFAULT',
	LEFT: 'LEFT',
	MIDDLE: 'MIDDLE',
	RIGHT: 'RIGHT',
};

const SERIES_LINKING = {
	UNLINK: 'UNLINK',
	MANUAL: 'MANUAL',
	PLANE: 'PLANE',
};

const CORNERSTONE_EVENTS = {
	CORNERSTONE_MANUAL_LINK_SYNCHRONIZER: 'CORNERSTONE_MANUAL_LINK_SYNCHRONIZER',
	CORNERSTONE_MG_SYNCHRONIZER: 'CORNERSTONE_MG_SYNCHRONIZER',
	CORNERSTONE_SCROLL_BETWEEN_SERIES: 'CORNERSTONE_SCROLL_BETWEEN_SERIES',
	CORNERSTONE_INVERT_MODIFIED: 'CORNERSTONE_INVERT_MODIFIED',
	CORNERSTONE_FLIP_MODIFIED: 'CORNERSTONE_FLIP_MODIFIED',
};

const PRIOR_MATCHING_MODEL_INSTANCES = [
	'body_part',
	'study_date',
	'study_status',
	'modality',
	'laterality',
	'procedure_code',
	'study_description',
];

const TOOL_GROUPS = {
	ADJUSTMENT: 'ADJUSTMENT',
	MARKUP: 'MARKUP',
};

const X_RAY_VIEW_CODES = {
	AP: 'AP',
	LAT: 'LAT',
	PA: 'PA',
	OBLIQUE: 'OBLIQUE',
	RLD: 'RLD',
	LLD: 'LLD',
	ANY_X_RAY: 'Any X-ray',
};

const CT_VIEW_CODES = {
	CT_AXIAL: 'CT: Axial',
	CT_CORONAL: 'CT: Coronal',
	CT_SAGITTAL: 'CT: Sagittal',
	CT_SCOUT: 'CT: Scout',
	CT_ANY: 'CT: Any',
};

const MR_VIEW_CODES = {
	MR_AXIAL: 'MR: Axial',
	MR_CORONAL: 'MR: Coronal',
	MR_SAGITTAL: 'MR: Sagittal',
	MR_SCOUT: 'MR: Scout',
	MR_ANY: 'MR: Any',
};

const PET_VIEW_CODES = {
	PET_AXIAL: 'PET: Axial',
	PET_CORONAL: 'PET: Coronal',
	PET_SAGITTAL: 'PET: Sagittal',
	PET_ANY: 'PET: Any',
};

const MG_VIEW_CODES = {
	MG_RCC: 'MG: RCC',
	MG_RFB: 'MG: RFB',
	MG_RISO: 'MG: RISO',
	MG_RLM: 'MG: RLM',
	MG_RLMO: 'MG: RLMO',
	MG_RML: 'MG: RML',
	MG_RMLO: 'MG: RMLO',
	MG_RSIO: 'MG: RSIO',
	MG_RXCC: 'MG: RXCC',
	MG_RXCCL: 'MG: RXCCL',
	MG_RXCCM: 'MG: RXCCM',
	MG_LCC: 'MG: LCC',
	MG_LFB: 'MG: LFB',
	MG_LISO: 'MG: LISO',
	MG_LLM: 'MG: LLM',
	MG_LLMO: 'MG: LLMO',
	MG_LML: 'MG: LML',
	MG_LMLO: 'MG: LMLO',
	MG_LSIO: 'MG: LSIO',
	MG_LXCC: 'MG: LXCC',
	MG_LXCCL: 'MG: LXCCL',
	MG_LXCCM: 'MG: LXCCM',
	MG_ANY_RIGHT: 'MG: Any right',
	MG_ANY_LEFT: 'MG: Any left',
	MG_ANY: 'MG: Any',
};

const MODALITY_VIEW_CODES = {
	NM: 'NM',
	XA: 'XA',
	ES: 'ES',
	OT: 'OT',
	OP: 'OP',
	IO: 'IO',
	VIDEO: 'Video',
	US: 'US',
	ANY: 'Any',
};

const VIEW_CODES = {
	...X_RAY_VIEW_CODES,
	...CT_VIEW_CODES,
	...MR_VIEW_CODES,
	...PET_VIEW_CODES,
	...MG_VIEW_CODES,
	...MODALITY_VIEW_CODES,
};

export {
	BROADCAST_EVENTS,
	LAYOUT_TYPE,
	SERIES_LINKING,
	CORNERSTONE_EVENTS,
	TOOL_GROUPS,
	MG_VIEW_CODES,
	VIEW_CODES,
	PRIOR_MATCHING_MODEL_INSTANCES,
};

export const PAN_MODIFIED = 'PAN_MODIFIED';
export const SCALE_MODIFIED = 'SCALE_MODIFIED';
export const ROTATE_MODIFIED = 'ROTATE_MODIFIED';
export const SCALING = 'scaling';

export const DISTANCE_COMPENSATOR = flag => (flag ? 1.1 : 1);

export const ZOOM_TYPES = {
	original: 'original',
	contain: 'viewport',
	cover: 'fill',
	custom: 'custom',
};

export const DEFAULT_SCALING_RULE = {
	type: 'scaling',
	values: { zoom: '', fit: 'viewport' },
};

export const DEFAULT_PAN_RULE = {
	type: 'pan',
	values: { pan: [0, 0] },
};

export const renderingEngineId = 'cornerstone3d';
export const shadowActiveViewportId = 'SHADOW_ACTIVE_VIEWPORT_ID';
