// core
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Box from '@mui/material/Box';
// components
import RsGrid from '@rs-ui/components/Grid';
import Toast from '@rs-ui/components/Toast';
import { MergePatientDrawer, MergeStudyDrawer } from '@rs-ui/components/Drawers/MergeDrawers';
import { MergePatientDrawerV2 } from '@rs-ui/components/Drawers/MergeDrawers/MergePatientDrawerV2';
import SendDrawer from './SendDrawer';
import SelectedResourcesTooltip from './SelectedResourcesTooltip';
import SelectedStudiesToolbar from './SelectedStudiesToolbar/SelectedStudiesToolbar';
import { WorklistPreAuthDrawer } from './WorklistPreAuthDrawer';
import { WorklistSpeedDialWithDrawer } from './WorklistSpeedDialWithDrawer';
import { MergePatientDrawer as MergePatientDrawerMod } from '@rs-components/Drawers/MergePatientsDrawer/MergePatientsDrawer';
import { useDeviceStore } from '@worklist-2/worklist/src/DocumentViewerV3/store/deviceStore';

// libs
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
// utils
import {
	fhirExtensionUrls,
	getColumnMapping,
	logInfo,
	scopeMapping,
	searchScopes,
	SelectionProvider,
	stringUtil,
	useAppModeContext,
	useAuth,
	useFhirDataLoader,
	useLoading,
	useMultiscreen,
	useRouter,
	useToast,
} from '@worklist-2/core/src';
import { SIGNREPORT_DELAY } from '@worklist-2/worklist/src/DocumentViewerV3/consts/consts';
import { useWorklist } from '../Worklist/contexts/WorklistContext';
import { getViewerURLs } from './getViewerURLs';
import { LinkPatientDrawer } from '@rs-components/Drawers/LinkDrawer/LinkPatientDrawer';
import { usePatientStore } from '@rs-ui/views/PatientInfoView/patientStore';
import useSavePrevReport from '@worklist-2/worklist/src/DocumentViewerV3/features/DocumentViewer/hooks/useSavePrevReport';
import {
	radPairContentUpdateCounter,
	resetRadPairContentUpdateCounter,
} from '@worklist-2/worklist/src/DocumentViewerV3/features/DocumentViewer/Viewports/ViewportTiptap/ViewportTipTapEditor/utils/updateRadPairHTML';
import TeachingFolderDrawer from '@rs-ui/components/Drawers/TeachingFolderDrawer/TeachingFolderDrawer';
import { useGlobalStore } from '@worklist-2/core/src/store';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';
import { getStatusIndicatorOptions } from '@worklist-2/ui/src/components/utils/gridUtils';
import { useRecognitionContext } from '@worklist-2/worklist/src/DocumentViewerV3/contexts/RecognitionContext';

const WorklistGrid = ({ className, worklistLayoutId }) => {
	// #region ==================== State ====================
	const [worklistLayoutPageNum] = useState(1);
	const [permissions, setPermissions] = useState(null);

	const { setToastUtility, toastOpen, toastMessage, handleToastClose, actions } = useToast();

	const [newWorklistId, setNewWorklistId] = useState({ id: '' });
	const [globalDefaultWorklist, setGlobalDefaultWorklist] = useState(null);

	// worklists should only be set from API calls
	// List of Objects
	const [worklists, setWorklists] = useState([]);
	const [reloadWorklistData, setReloadWorklistData] = useState(false);
	const [showSaveBtn, setShowSaveBtn] = useState(false);
	const [sendDrawerOpen, setSendDrawerOpen] = useState(false);
	const [selectedRecord, setSelectedRecord] = useState(null);
	const [teachingDrawerOpen, setTeachingDrawerOpen] = useState(false);

	// Used to determine whether the send report button is clicked from the wheel (single report) or the multiselect widget.
	const [isSendMultiReport, setIsSendMultiReport] = useState(false);

	const [readyForData, setReadyForData] = useState(false);

	const [imageViewerUrl, setImageViewerUrl] = useState(null);
	const [documentViewerUrl, setDocumentViewerUrl] = useState(null);

	const [isMergeStudyDrawerOpened, setMergeStudyDrawerOpened] = useState(false);
	const [isMergePatientDrawerOpened, setMergePatientDrawerOpened] = useState(false);
	const [isLinkPatientDrawerOpened, setLinkPatientDrawerOpened] = useState(false);
	const [isLinkMergeDrawerLoading, setIsLinkMergeDrawerLoading] = useState(false);

	const [studiesToRemove, setStudiesToRemove] = useState(null);
	const [reportSaved, setReportSaved] = useState(false);
	// #endregion

	// #region PatientStore
	const { resetPatient } = usePatientStore();
	const { setCurrentStudyInfo } = useGlobalStore();
	// #endregion

	// #region ==================== Hooks ====================
	// set the context here so the grid knows where to search
	const {
		authorized,
		loggedInUser,
		getGuiRoleByOrganizationAndResource,
		displaySettings,
		getGuiPrivilegeByOrganizationAndResourceAsync,
	} = useAuth();
	const { updateAppMode } = useAppModeContext();

	const fhirDataLoaderWorklist = useFhirDataLoader({ scope: searchScopes.worklistLayout });
	const fhirReportLoader = useFhirDataLoader({ scope: searchScopes.diagnosticReport });
	const studyLoader = useFhirDataLoader({ scope: searchScopes.studyRegular });

	const proactEnablePatientInfoMergeLink = useBooleanFlagValue('proact-enable-patient-info-merge-link');
	const proactEnableVisitOnOmegaDial = useBooleanFlagValue('proact-enable-visit-on-omega-dial');
	const proactEnableBilling = useBooleanFlagValue('proact-enable-billing');
	const proactMergePatientV2 = useBooleanFlagValue('proact-merge-patient-v-2');
	const sprinterOmegaaiMultiscreen = useBooleanFlagValue('sprinter-omegaai-multiscreen');
	const maven2061TeachingFolder = useBooleanFlagValue('maven-2061-teaching-folder');
	const galaxyDvEnableRadPairIntegration = useBooleanFlagValue('galaxy-dv-enable-rad-pair-integration');
	const galaxyDvEnableGlobalStoreWithIndexedDb = useBooleanFlagValue('galaxy-dv-enable-global-store-with-IndexedDB');
	const enableCustomStudyPriority = useBooleanFlagValue('proact-enable-custom-study-priority');
	const gridCustomHeader = useBooleanFlagValue('meta-grid-custom-header');
	const galaxyDvAutoAssignReadingPhysicianAfterSignOff = useBooleanFlagValue(
		'galaxy-dv-auto-assign-reading-physician-after-sign-off'
	);
	const galaxyDvPerformancePhase2 = useBooleanFlagValue('galaxy-dv-performance-phase-2');
	const crossGetPrivilegeOnDemand = useBooleanFlagValue('cross-get-privilege-on-demand');
	const proactEnableNewVisitOrderPage = useBooleanFlagValue('proact-enable-new-visit-order-page');

	const { loading, setLoading } = useLoading();
	const { openToSettingScreen } = useMultiscreen();
	const { goTo } = useRouter();
	const currWorklistIdRef = useRef();
	// Note that "All" is used because useColumnList loads translation based on scope name
	const { t } = useTranslation(['All', 'workList', 'preauthorizationDrawer', 'notAuthorized', 'patientInfo']);

	const {
		filters,
		setFilters,
		savedFilters,
		setSavedFilters,
		sort,
		setSort,
		savedSorts,
		setSavedSorts,
		selectedWorklistId,
		setSelectedWorklistId,
		selectedWorklistName,
		setSelectedWorklistName,
		visitedWorklist,
		dataGridRefValue,
		setDataGridRefValue,
		scrollPosition,
		setScrollPosition,
		currWorklistColumns,
		setCurrWorklistColumns,
		columnSizing,
		setColumnSizing,
		savedColumnSizing,
		setSavedColumnSizing,
		savedWorklistColumns,
		setSavedWorklistColumns,
	} = useWorklist();
	const { toggleRequestDevice } = useRecognitionContext();
	const { deviceManager } = useDeviceStore();
	// #endregion

	useEffect(() => {
		// Document Viewer
		setTimeout(() => {
			handleSignReport();
		}, SIGNREPORT_DELAY);
	}, []);
	/**
	 * Document Viewer
	 * save Report after user go back to worklist
	 */
	useSavePrevReport();

	// #region ==================== Events ====================
	/**
	 * Document Viewer
	 * Sign Report after redirect and alert
	 */
	useEffect(() => {
		if (galaxyDvAutoAssignReadingPhysicianAfterSignOff && reportSaved) {
			const studyData = dataGridRefValue?.current?.getRowData('id', visitedWorklist);
			studyLoader
				.patch(studyData?.id, 'interpreter', [
					{
						id: loggedInUser.id,
						reference: `Practitioner/${loggedInUser.id}`,
					},
				])
				.then(res => {
					const updatedData = {
						id: visitedWorklist,
						data: {
							...studyData,
							readingPhysician: res?.interpreter?.[0].display,
						},
					};
					dataGridRefValue?.current?.insertUpdateRow(updatedData);
				});
			setReportSaved(false);
		}
	}, [reportSaved, dataGridRefValue]);

	const handleSignReport = async () => {
		const isAfterSave = window.location.hash === '#save';
		const isAfterNoNextStudy = window.location.hash === '#no-further-studies';
		if (!isAfterSave && !isAfterNoNextStudy) return;
		if (isAfterNoNextStudy) {
			setToastUtility(true, t('No further studies, Redirected to worklist!', { ns: 'workList' }));
		}
		const signData = localStorage.getItem('signData');
		logInfo('WorklistGrid', `signData available ${!signData ? 'no' : 'yes'}`);
		if (!signData) return;

		const popstateListener = () => goTo.any(1);

		window.addEventListener('popstate', popstateListener);

		const { document, payload, type } = JSON.parse(signData);

		const { status } =
			type === 'update'
				? await fhirReportLoader.update(`${document.id}?callDone=true`, payload, null, null, null, true)
				: await fhirReportLoader.save({ callDone: 'true' }, payload, true);
		if (status === 200 || status === 201) {
			setToastUtility(
				true,
				t('Action completed successfully.', {
					ns: 'workList',
				})
			);
		} else {
			setToastUtility(
				true,
				t('Sorry, error while signing the report, please try again', {
					ns: 'workList',
				})
			);
		}
		setReportSaved(true);
		localStorage.removeItem('signData');
		window.removeEventListener('popstate', popstateListener);
		if (galaxyDvEnableRadPairIntegration && radPairContentUpdateCounter === 1) {
			resetRadPairContentUpdateCounter();
		}
	};

	const fetchHomePermissions = useCallback(
		async (organizationId, referringFacilityId, resource) => {
			const temPermissions = {};
			if (crossGetPrivilegeOnDemand) {
				const organizationPermission = await getGuiPrivilegeByOrganizationAndResourceAsync(
					organizationId,
					resource,
					referringFacilityId
				);

				if (organizationPermission) {
					Object.keys(organizationPermission).forEach(item => {
						temPermissions[item] = organizationPermission[item];
					});
					setPermissions(temPermissions);
				}
			} else {
				const organizationPermission = getGuiRoleByOrganizationAndResource(
					organizationId,
					resource,
					referringFacilityId
				);

				if (organizationPermission) {
					Object.keys(organizationPermission).forEach(item => {
						temPermissions[item] = organizationPermission[item];
					});
					setPermissions(temPermissions);
				}
			}
		},
		[crossGetPrivilegeOnDemand]
	);

	const onWheelSendStudyClicked = useCallback(record => {
		setSelectedRecord([record]);
		setSendDrawerOpen(true);
		setIsSendMultiReport(false); // Only sends a single report when clicked from the wheel.
	}, []);

	const columnMapping = useMemo(() => getColumnMapping(scopeMapping[searchScopes.worklist]), []);
	const extraParam = {
		defaultlayoutsystem: 'true',
		active: 'true',
	};

	currWorklistIdRef.current = selectedWorklistId;

	const fetchData = useCallback(
		async (page, user) => {
			try {
				if (authorized && !loading && fhirDataLoaderWorklist && loggedInUser) {
					setLoading(true);
					return fhirDataLoaderWorklist
						.load({
							page,
							user: user || loggedInUser.id,
							extraValue: extraParam,
						})
						.then(result => {
							if (result && result.length > 0) {
								let fetchedWorklists = _.compact(
									_.flatten(
										_.reduce(
											result,
											(a, b) =>
												_.concat(a, [
													{
														id: b.id,
														name: b.name,
														columns: b.rawData.columns,
														label: b.name,
														isCurrent: b.isCurrent,
														isDefault: b.isDefault,
														practitioner: b.practitioner,
														resourceType: b.resourceType,
														active: b.active,
														role: b.role,
														organization: b.organization,
													},
												]),
											[]
										)
									)
								);
								fetchedWorklists = _.filter(fetchedWorklists, item => {
									if (item.id === '1') {
										setGlobalDefaultWorklist(item);
									} else {
										return item;
									}
								});
								setWorklists(fetchedWorklists);
							}
						})
						.catch(console.error);
				}
			} finally {
				setLoading(false);
			}
		},
		[fhirDataLoaderWorklist, setWorklists, authorized, loading, setLoading]
	);

	useEffect(() => {
		if (!isLinkPatientDrawerOpened || !isMergePatientDrawerOpened) {
			resetPatient();
		}
	}, [isLinkPatientDrawerOpened, isMergePatientDrawerOpened]);

	useEffect(() => {
		if (authorized) {
			updateAppMode('worklist');

			if (loggedInUser) {
				setImageViewerUrl(loggedInUser.imageViewerUrl);
				setDocumentViewerUrl(loggedInUser.documentViewerUrl);
				fetchData(worklistLayoutPageNum, loggedInUser.id).catch(console.error);
			}
		}
	}, [authorized, reloadWorklistData, worklistLayoutPageNum, loggedInUser]);

	const findDefaultWorklistData = worklistsArr => {
		const worklist = _.find(worklistsArr, wl => wl.isDefault);
		return worklist
			? { id: worklist.id, name: worklist.name }
			: { id: worklistsArr[0].id, name: worklistsArr[0].name };
	};

	useEffect(() => {
		// if there was no worklist previously set when worklists was changed, set the current worklist to the default worklist
		const findWorklistObject = id => {
			if (id === -1) {
				return findDefaultWorklistData(worklists);
			}
			const worklist = worklists.find(w => w.id === id);
			return worklist ? { id: worklist.id, name: worklist.name } : findDefaultWorklistData(worklists);
		};

		if (Array.isArray(worklists) && worklists.length > 0) {
			// if currWorklist is set, check to see that it still exists in worklists
			// if it doesn't, then set currWorklist to the default Worklist
			const worklistId = newWorklistId.id || worklistLayoutId || selectedWorklistId || -1;
			const worklistObject = findWorklistObject(worklistId);
			currWorklistIdRef.current = worklistObject.id;
			setNewWorklistId({ id: '' });
			loadWorklistLayout(worklistObject.id, worklistObject.id !== selectedWorklistId);
		} else if (globalDefaultWorklist) {
			// Assumption is that we should always have a global default worklist
			loadWorklistLayout(globalDefaultWorklist.id, true);
		}
	}, [worklists, worklistLayoutId]);

	const assignColumnAndFilterValues = useCallback(
		colObj => {
			let column = '';
			let filter = {};
			let sort = null;

			const entry = columnMapping[colObj.name];

			if (entry) {
				const mapping = entry;
				const searchId = mapping?.searchParameter ? mapping.searchParameter : mapping?.searchValueSet;
				let tempVal;
				let tempDisplayVal;
				try {
					tempVal = colObj.filter ? JSON.parse(colObj.filter) : '';
					tempDisplayVal = colObj.displayValue ? JSON.parse(colObj.displayValue) : '';
				} catch {
					tempVal = colObj.filter ?? '';
					tempDisplayVal = colObj.displayValue ?? '';
				}
				const values = _.isArray(tempVal) ? _.flatten(tempVal).filter(String) : tempVal.toString(); // Convert the value to string if its a number to fix an error on filter panel
				const displayValue = _.isArray(tempDisplayVal)
					? _.flatten(tempDisplayVal).filter(String)
					: tempDisplayVal.toString();
				column = colObj.name;

				filter = {
					label: entry.label,
					columnIdentity: colObj.name,
					searchParameter: searchId,
					// Filter property in colObj is returned as a String from the
					// backend. Need to parse it as a JSON to get the array.
					values,
					displayValue,
					searchValueSet: mapping?.searchValueSet,
					filterType: mapping?.filterType,
					valueSetExtraParam: mapping?.valueSetExtraParam,
				};

				if (mapping?.searchParameter === 'statusIndicator') {
					filter.customFilterOptions = mapping?.customFilterOptions;
				}

				// Get sort value
				if (colObj.sort && colObj.sort !== 'none') {
					sort = colObj.sort === 'asc' ? colObj.name : `-${colObj.name}`;
				}
			}

			return [column, filter, sort];
		},
		[columnMapping]
	);

	/**
	 * Loads the layout of a worklist based on its ID and updates the worklist columns, filters, and sorting.
	 * @param {string} id - The ID of the worklist to load.
	 * @param {boolean} loadFilters - Whether or not to load the filters.
	 * @returns {void}
	 */
	const loadWorklistLayout = (id, loadFilters) => {
		let wlObject;

		// when the current Worklist is changed, update the worklist columns,
		if (worklists?.length > 0) {
			wlObject = worklists.find(w => w.id === id);
		} else {
			// Assumption is that the global default worklist will never be in the worklists list
			wlObject = globalDefaultWorklist;
		}

		if (wlObject) {
			// For the status indicator filter in the worklist grid, it is necessary to pass the configuration options
			// to render or hide these filters. Since this comes from the layout object, we use "bind" to assing this
			// to the cell rendering function.
			if (wlObject.resourceType === 'WorkListLayout') {
				const columnConfig = wlObject.columns.find(x => x.name === 'statusIndicator');
				if (columnConfig) {
					const { statusIndicators } = columnConfig;
					const statusIndicatorOptions = getStatusIndicatorOptions(statusIndicators);
					columnMapping.statusIndicator.customFilterOptions = statusIndicatorOptions;
					columnMapping.statusIndicator.options.statusIndicators = statusIndicators;
				}
			}

			const columnObjects = wlObject.columns;
			const cols = [];
			const filts = [];
			let wlSortArr = [];
			const sizes = {};
			for (const colObj of columnObjects) {
				const [column, filter] = assignColumnAndFilterValues(colObj);
				cols.push(column);
				filts.push(filter);
				if (colObj.sort === 'asc' || colObj.sort === 'desc') {
					wlSortArr.push(colObj);
				}
				if (colObj.size != null) {
					sizes[column] = +colObj.size;
				}
			}
			const orderedWlSortArr = _.orderBy(wlSortArr, ['sortOrder'], ['asc']);
			wlSortArr = [];
			orderedWlSortArr?.forEach(sortCol => {
				wlSortArr.push(sortCol.sort === 'asc' ? sortCol.name : `-${sortCol.name}`);
			});
			// Get rid of empty strings and columns
			let newCols = _.filter(cols, e => !_.isEmpty(e));
			const newFilts = _.filter(filts, e => e.values?.length !== 0);

			// inject critical finding column
			if (!_.includes(newCols, 'flagCol')) {
				newCols = ['flagCol', ...newCols];
			}

			// inject spacing column
			if (!_.includes(newCols, 'spacingCol')) {
				newCols = [...newCols, 'spacingCol'];
			}

			setSavedWorklistColumns(newCols);
			setSavedColumnSizing(sizes);
			setSelectedWorklistId(wlObject.id);
			setSelectedWorklistName(wlObject.name);
			setSavedSorts([...wlSortArr]);
			setSavedFilters(newFilts);

			if (loadFilters) {
				setFilters(newFilts);
				setSort([...wlSortArr]);
				setCurrWorklistColumns(newCols);
				setColumnSizing(sizes);

				// Disable save button after loading all states
				setShowSaveBtn(false);

				// Reset scroll position
				setScrollPosition({
					scrollLeft: 0,
					scrollTop: 0,
				});
			} else {
				// Check changing state after loading
				checkIfWorklistHasChanged();
			}
		}
	};

	useEffect(() => {
		checkIfWorklistHasChanged();
	}, [filters, currWorklistColumns, columnSizing, sort]);

	const checkIfWorklistHasChanged = useCallback(() => {
		// enable save worklist button if there is a change in the columns or the filters

		// If there are any elements in currWorklistColumns that are different from
		// the corresponding element in savedWorklistColumns (columns have
		// changed in currWorklistColumns) or if savedWorklistColumns
		// has a different length than currWorklistColumns (columns have been added
		// or removed from currWorklistColumns), then the columns have changed.
		// Order matters
		const columnsHaveChanged =
			currWorklistColumns.length > 0 &&
			(savedWorklistColumns.length !== currWorklistColumns.length ||
				_.isNil(
					_.find(
						currWorklistColumns,
						(e, idx) => !_.isNil(savedWorklistColumns[idx]) && e === savedWorklistColumns[idx]
					)
				));

		// isEqual performs a deep comparison of objects
		const columnSizingHasChanged = !_.isEqual(columnSizing, savedColumnSizing);

		// If there are any elements in the filters array that does not have a matching
		// element in the savedFilters array (or vice-versa), then the filters have
		// changed. (xor returns all elements that are only in 1, but not both of
		// the arrays, so if xor returns a non-empty array, that means that the two
		// arrays are different)
		// Order does not matter.
		const filtersHaveChanged = !_.isEmpty(
			_.xorWith(
				filters,
				savedFilters,
				(a, b) =>
					a.label === b.label &&
					_.isEmpty(
						_.xor(
							Array.isArray(a.values) ? a.values : [a.values],
							Array.isArray(b.values) ? b.values : [b.values]
						)
					)
			)
		);

		const sortHasUpdated = !_.isEqual(sort, savedSorts);

		// Need to add comparator for column size later
		const hasChange = columnsHaveChanged || filtersHaveChanged || columnSizingHasChanged || sortHasUpdated;

		// Do not show save button on default worklist (id = 0 || 1)
		if (selectedWorklistId !== 0 && selectedWorklistId !== 1) {
			setShowSaveBtn(hasChange);
		}

		return hasChange;
	}, [filters, currWorklistColumns, columnSizing, sort]);

	const updateSort = val => {
		setSort(val);
	};

	const onSubmitWorklist = useCallback(
		async payload => {
			// create new worklist
			if (authorized) {
				return fhirDataLoaderWorklist.save({}, payload).then(result => {
					if (result && result.status === 201) {
						const newWorklist = result.data;
						// don't bother refreshing worklists if we created a new worklist for a different user
						if (newWorklist.practitioner?.id === loggedInUser.id) {
							setNewWorklistId({ id: result.data.id });
							setReloadWorklistData(prev => !prev);
						} else if (!newWorklist.practitioner) {
							setReloadWorklistData(prev => !prev);
						}
					}
					return result;
				});
			}
			return null;
		},
		[fhirDataLoaderWorklist, setReloadWorklistData, authorized, loggedInUser]
	);

	const onDeleteWorklist = useCallback(
		id => {
			if (authorized) {
				fhirDataLoaderWorklist.delete(id).then(result => {
					if (result) {
						if (selectedWorklistId === id) {
							setNewWorklistId({ id: '-1' });
						}
						setReloadWorklistData(prev => !prev);
					}
					return result;
				});
			}
		},
		[fhirDataLoaderWorklist, authorized, worklists, setReloadWorklistData, setNewWorklistId, selectedWorklistId]
	);

	const onUpdateWorklist = useCallback(
		async (id, payload, reloadWL = true) => {
			if (authorized) {
				return fhirDataLoaderWorklist.update(id, payload).then(result => {
					const idx = worklists.findIndex(w => w.id === result?.id);
					if (idx > -1) {
						if (reloadWL) {
							worklists[idx] = result;
							loadWorklistLayout(id, true);

							// This is to update any components that rely only on worklists
							setWorklists(wls => {
								const newWls = [...wls];
								newWls[idx] = result;
								return newWls;
							});
						}

						// Disable save button after saving
						setShowSaveBtn(false);
					} else {
						console.error(`Worklist Layout Id does not match`);
					}
					return result;
				});
			}
			return null;
		},
		[fhirDataLoaderWorklist, setReloadWorklistData, authorized, worklists, loadWorklistLayout]
	);

	const onWorklistSelected = useCallback(
		selectedWorklist => {
			currWorklistIdRef.current = selectedWorklist.key;
			loadWorklistLayout(selectedWorklist.key, true);
		},
		[worklists]
	);

	const onSaveClicked = useCallback(async () => {
		// save, then perhaps reload the worklist
		const worklist = worklists.find(
			elem =>
				// Need to explicitly return value
				elem.id === selectedWorklistId
		);
		const payload = {
			id: worklist?.id,
			active: worklist.active,
			columns: currWorklistColumns
				.filter(col => !columnMapping[col]?.options?.excludeFromSave)
				.map(col => {
					const filterObj = filters.find(fil => fil.columnIdentity === col);
					// Whatever is in the filter property is converted into a string
					// in the backend
					const filter = filterObj ? filterObj.values : null;
					const values = filterObj ? filterObj.values : null;
					const displayValue = filterObj ? filterObj.displayValue : null;
					let columnSortValue = null;
					let colSortOrder = null;
					const columnSort = sort.find(it => it.includes(col));

					if (columnSort) {
						if (columnSort[0] === '-') {
							columnSortValue = 'desc';
						} else {
							columnSortValue = 'asc';
						}
						colSortOrder = sort.indexOf(columnSort).toString();
					}

					const columnData = {
						name: col,
						...(columnSizing[col] ? { size: columnSizing[col] } : null),
						...(filter && (_.isArray(filter) ? !_.isEmpty(filter) : true) && { filter }),
						...(values && (_.isArray(values) ? !_.isEmpty(values) : true) && { values }),
						...(displayValue && !_.isEmpty(displayValue) && { displayValue }),
						...(columnSortValue ? { sort: columnSortValue } : null),
						...(colSortOrder ? { sortOrder: colSortOrder } : null),
					};

					// If the column is a status indicator, add the indicator config to the column data
					if (col === 'statusIndicator') {
						const indicatorConfig = worklist.columns.find(x => x.name === 'statusIndicator');

						if (indicatorConfig) {
							columnData.statusIndicators = indicatorConfig.statusIndicators;
						}
					}

					return columnData;
				}),
			name: worklist.name,
			isDefault: worklist.isDefault,
			isCurrent: worklist.isCurrent,
			resourceType: worklist.resourceType,
			practitioner: worklist.practitioner,
			// Hardcoded for now; need to change later
			organization: !worklist.practitioner ? worklist.organization : { display: '', id: '' }, // if role based worklist set organization
			role: !worklist.practitioner ? worklist.role : { display: '', id: '' }, // if role based worklist set role information

			extension: [
				{
					url: 'http://www.ramsoft.com/fhir/extension/flagsave',
					valueString: worklist.practitioner ? '0' : '2', // if role based worklist set flagsave to 2
				},
			],
		};
		await onUpdateWorklist(worklist.id, payload, true);
	}, [worklists, filters, selectedWorklistId, currWorklistColumns, columnSizing]);

	const wheelMenuItems = useMemo(
		() => [
			{
				label: t('workList:study'),
				icon: 'study',
				visible: permissions?.study?.read,
			},
			{
				label: t('workList:documentViewer'),
				icon: 'documentviewer',
				visible: permissions?.['document viewer']?.read,
			},
			{
				label: t('workList:imageViewer'),
				icon: 'imageviewer',
				visible: permissions?.['image viewer']?.read,
			},
			{
				label: t('workList:studyHistory'),
				icon: 'studyexplorer',
				visible: permissions?.['study history']?.read,
			},
			{
				label: t('workList:send'),
				icon: 'sendstudy',
				visible: permissions?.send?.read,
			},
			{
				label: t('workList:billing'),
				icon: 'billing',
				visible: proactEnableBilling, // need UAC
			},
			{
				label: t('workList:patient'),
				icon: 'patient',
				visible: permissions?.patient?.read,
			},
			{
				label: t('workList:visit'),
				icon: 'visit',
				visible: proactEnableVisitOnOmegaDial && permissions?.visit?.read,
			},
			{
				label: t('workList:order'),
				icon: 'order',
				visible: permissions?.order?.read,
			},
		],
		[permissions, proactEnableBilling, proactEnableVisitOnOmegaDial]
	);

	function isValidGUID(guid) {
		const guidPattern = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
		return guidPattern.test(guid);
	}

	const handleWheelClick = useCallback(
		async (item, record) => {
			const patientID = record?.patientID;
			const internalPatientId = record?.subject?.id;
			let studyInstanceUid = record?.identifier?.find(recordItem => recordItem.system === 'urn:dicom:uid')?.value;
			studyInstanceUid = studyInstanceUid?.replace('urn:oid:', '');
			const issuerOfPatientId = record?.extension?.find(ext => ext.url === fhirExtensionUrls.organization.issuer)
				?.valueReference?.display;
			const internalStudyId = record?.id;
			const orderId = record?.basedOn?.[0]?.id;
			const internalManagingOrganizationID = record?.internalManagingOrganizationID;
			const { referringFacilityId } = record;
			let creatorBlumeId = record?.patientID?.toLowerCase() || null;
			if (!isValidGUID(creatorBlumeId)) {
				creatorBlumeId = null;
			}
			switch (item.icon) {
				case 'patient':
					goTo.patientDetail({ patientId: internalPatientId, screen: 'info' });
					return;
				case 'order':
					goTo.any(`/order/${orderId}`); // goTo.orderDetail({ orderId, patientId: internalPatientId }); // #TODO: useRouter
					return;
				case 'billing':
					goTo.any(`/order/${orderId}/billing/${internalStudyId}/generate-invoice`); // goTo.orderGenerateInvoice({ orderId, patientId: internalPatientId, studyId: internalStudyId }); // #TODO: useRouter
					return;
				case 'study':
					if (proactEnableNewVisitOrderPage) {
						goTo.any(`/order/${orderId}#study=${internalStudyId}`);
					} else {
						goTo.any(`/order/${orderId}#study-list`); // goTo.orderStudyList({ orderId, patientId: internalPatientId }); // #TODO: useRouter
					}
					return;

				case 'sendstudy':
					if (studyInstanceUid) {
						onWheelSendStudyClicked(record);
					} else {
						setToastUtility(true, t('workList:selectedStudyToast'));
					}

					return;
				case 'studyexplorer':
					goTo.patientDetail({ patientId: internalPatientId, screen: 'study-history' });
					return;
				case 'imageviewer':
					if (galaxyDvPerformancePhase2) {
						setCurrentStudyInfo(record);
					}
					if (imageViewerUrl && !(sprinterOmegaaiMultiscreen && displaySettings && displaySettings.enabled)) {
						const studyUrl = imageViewerUrl.replace('<studyuid>', studyInstanceUid);
						window.open(studyUrl);
					} else {
						const { documentViewerNavigateURL, imageViewerNavigateURL } = getViewerURLs({
							patientID,
							internalPatientId,
							orderId,
							internalStudyId,
							internalManagingOrganizationID,
							issuerOfPatientId,
							referringFacilityId,
							studyInstanceUid,
							imageViewerUrl,
							documentViewerUrl,
							creatorBlumeId,
						});

						if (sprinterOmegaaiMultiscreen && window.screen.isExtended) {
							await openToSettingScreen(
								documentViewerNavigateURL,
								'DV',
								documentViewerUrl,
								false,
								record
							);
							const resultIV = await openToSettingScreen(imageViewerNavigateURL, 'IV', imageViewerUrl);
							if (!resultIV) {
								goTo.any(imageViewerNavigateURL); // #TODO: useRouter safe-type this !
							}
						} else {
							goTo.any(imageViewerNavigateURL); // #TODO: useRouter safe-type this !
						}
					}

					return;
				case 'documentviewer':
					if (galaxyDvEnableGlobalStoreWithIndexedDb) {
						setCurrentStudyInfo(record);
					} else {
						localStorage.setItem('currentStudyInfo', JSON.stringify(record));
					}
					if (
						documentViewerUrl &&
						!(sprinterOmegaaiMultiscreen && displaySettings && displaySettings.enabled)
					) {
						window.open(documentViewerUrl.replace('<studyuid>', studyInstanceUid));
					} else {
						const { documentViewerNavigateURL } = getViewerURLs({
							patientID,
							internalPatientId,
							orderId,
							internalStudyId,
							internalManagingOrganizationID,
							issuerOfPatientId,
							referringFacilityId,
							studyInstanceUid,
							creatorBlumeId,
						});

						if (sprinterOmegaaiMultiscreen && window.screen.isExtended) {
							const resultDV = await openToSettingScreen(
								documentViewerNavigateURL,
								'DV',
								documentViewerUrl,
								false,
								record
							);
							if (!resultDV) {
								goTo.any(documentViewerNavigateURL); // #TODO: safe-type this !
							}
						} else {
							goTo.any(documentViewerNavigateURL); // #TODO: safe-type this !
						}
					}
					// request permission for connected HID device
					const pairedDevice = JSON.parse(localStorage.getItem('pairedDevice'));
					if (pairedDevice && deviceManager) {
						const devices = deviceManager.getDevices();
						logInfo('MIKE::WorklistGrid::', 'devices permitted already', devices);
						if (devices?.length <= 0) {
							await toggleRequestDevice(true);
						}
					}
					return;
				default:
					console.error(`Could not find action for ${item.id}`);
			}
		},
		[imageViewerUrl, documentViewerUrl, displaySettings]
	);

	const handleWorklistDoubleClick = useCallback(async () => {
		const pairedDevice = JSON.parse(localStorage.getItem('pairedDevice'));
		if (pairedDevice && deviceManager) {
			const devices = deviceManager.getDevices();
			logInfo('MIKE::WorklistGrid::', 'double-click - devices permitted already', devices);
			if (devices?.length <= 0) {
				await toggleRequestDevice(true);
			}
		}
	}, []);

	/**
	 * When the user clicks send from the floating toolbar
	 */
	const onSendClick = (record = null) => {
		let studyInstanceUid;

		if (record && record.length > 0) {
			// We only support single study for send study drawer for now.
			for (let index = 0; index < record.length; index++) {
				const study = record[index];
				studyInstanceUid = study?.rawData?.identifier?.find(
					recordItem => recordItem.system === 'urn:dicom:uid'
				)?.value;
				studyInstanceUid = studyInstanceUid?.replace('urn:oid:', '');
				if (studyInstanceUid) {
					break;
				}
			}

			setSelectedRecord(record);
			setIsSendMultiReport(true); // Sends multiple reports when clicked from the multiselect widget.
		}

		// If we selectd study and the study doesn't have the study UID, then we display a toast msg and don't opent the send study drawer
		if (!!record && !studyInstanceUid) {
			setToastUtility(true, t('workList:selectedStudyToast'));
		} else {
			setSendDrawerOpen(true);
		}
	};

	const onWheelRowSelect = useCallback(
		async (organizationId, referringFacilityId) => {
			await fetchHomePermissions(organizationId, referringFacilityId, 'Home');
		},
		[fetchHomePermissions]
	);

	/**
	 * When the user clicks add to teaching folder from the floating toolbar
	 */
	const onAddToTFClick = (record = null) => {
		// If we selectd study and the study doesn't have the study UID, then we display a toast msg and don't opent the send study drawer
		if (!record || record.length === 0) {
			setToastUtility(true, t('workList:NoStudySelected'));
		} else {
			setSelectedRecord(record);
			setTeachingDrawerOpen(true);
		}
	};

	const onScrollChange = useCallback(
		_.debounce(scrollLocal => {
			setScrollPosition(scrollLocal);
			console.log('scroll position', scrollLocal);
		}, 1000),
		[setScrollPosition]
	);
	// #endregion

	return (
		<Box data-testid="worklist-grid" position="relative" sx={{ overflow: 'hidden' }}>
			{
				<>
					<Toast
						anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
						message={toastMessage}
						open={toastOpen}
						onClose={handleToastClose}
						onUndo={actions.onUndo}
					/>

					<SelectionProvider>
						<RsGrid
							canResizeColumns
							enableDraggableRows
							selectableRows
							className={className}
							columnSizing={columnSizing}
							enableCustomStudyPriority={enableCustomStudyPriority}
							filters={filters}
							gridCustomHeader={gridCustomHeader}
							name="worklist"
							ready={readyForData}
							rowsToRemove={studiesToRemove}
							scrollPosition={scrollPosition}
							setColumnSizing={setColumnSizing}
							setCurrWorklistColumns={setCurrWorklistColumns}
							setDataGridRefValue={setDataGridRefValue}
							setFilters={setFilters}
							setReady={setReadyForData}
							setRowsToRemove={setStudiesToRemove}
							setSort={updateSort}
							sort={sort}
							title={stringUtil.toTileCase(selectedWorklistName)}
							wheelMenuItems={wheelMenuItems}
							onDoubleClickInRow={handleWorklistDoubleClick}
							onScrollChange={onScrollChange}
							onWheelClick={handleWheelClick}
							onWheelRowSelect={onWheelRowSelect}
							scope={searchScopes.all}
							/* Columns will be determined by the speed dial/column builder/etc, then passed here */
							columns={currWorklistColumns}
						/>

						<SelectedStudiesToolbar t={t} onAddToTFClick={onAddToTFClick} onSendClick={onSendClick} />

						<SelectedResourcesTooltip
							setIsLinkMergeDrawerLoading={setIsLinkMergeDrawerLoading}
							t={t}
							onLinkPatientDrawerOpen={setLinkPatientDrawerOpened}
							onMergePatientDrawerOpen={setMergePatientDrawerOpened}
							onMergeStudyDrawerOpen={setMergeStudyDrawerOpened}
						/>

						<WorklistSpeedDialWithDrawer
							currWorklistId={selectedWorklistId}
							fetchData={fetchData}
							globalDefaultWorklist={globalDefaultWorklist}
							reloadWorklistData={() => setReloadWorklistData(prev => !prev)}
							showSaveBtn={showSaveBtn}
							worklists={worklists}
							onDeleteWorklist={onDeleteWorklist}
							onModifyWorklist={onUpdateWorklist}
							onSaveClicked={onSaveClicked}
							onSubmitWorklist={onSubmitWorklist}
							onUpdateWorklist={onUpdateWorklist}
							onWorklistSelected={onWorklistSelected}
						/>

						<SendDrawer
							drawerOpen={sendDrawerOpen}
							isSendMultiReport={isSendMultiReport}
							setDrawerOpen={open => {
								setSendDrawerOpen(open);
								if (!open) {
									setSelectedRecord(null);
								}
							}}
							studies={selectedRecord}
						/>

						{maven2061TeachingFolder && (
							<TeachingFolderDrawer
								isAddingStudies
								drawerOpen={teachingDrawerOpen}
								setDrawerOpen={open => {
									setTeachingDrawerOpen(open);
									if (!open) {
										setSelectedRecord(null);
									}
								}}
							/>
						)}
						<MergeStudyDrawer
							drawerOpen={isMergeStudyDrawerOpened}
							setDrawerOpen={setMergeStudyDrawerOpened}
							setStudiesToRemove={setStudiesToRemove}
							t={t}
							toastUtility={setToastUtility}
						/>

						{proactEnablePatientInfoMergeLink && (
							<LinkPatientDrawer
								drawerOpen={isLinkPatientDrawerOpened}
								isLinkDrawerLoading={isLinkMergeDrawerLoading}
								setDrawerOpen={setLinkPatientDrawerOpened}
								toastUtility={setToastUtility}
							/>
						)}

						{proactMergePatientV2 ? (
							<>
								{proactEnablePatientInfoMergeLink ? (
									<MergePatientDrawerMod
										drawerOpen={isMergePatientDrawerOpened}
										isMergeDrawerLoading={isLinkMergeDrawerLoading}
										setDrawerOpen={setMergePatientDrawerOpened}
										setToastUtility={setToastUtility}
									/>
								) : (
									<MergePatientDrawerV2
										drawerOpen={isMergePatientDrawerOpened}
										setDrawerOpen={setMergePatientDrawerOpened}
										setStudiesToRemove={setStudiesToRemove}
										t={t}
										toastUtility={setToastUtility}
									/>
								)}
							</>
						) : (
							<MergePatientDrawer
								drawerOpen={isMergePatientDrawerOpened}
								setDrawerOpen={setMergePatientDrawerOpened}
								setStudiesToRemove={setStudiesToRemove}
								t={t}
								toastUtility={setToastUtility}
							/>
						)}

						<WorklistPreAuthDrawer t={t} />
					</SelectionProvider>
				</>
			}
		</Box>
	);
};

export default React.memo(WorklistGrid);
