import fhirExtensionUrls from '@worklist-2/core/src/fhir/extension/fhirExtensionUrls';

const NO_DATA_LABEL = 'N/A';

const DEFAULT_VALUES = {
	status: NO_DATA_LABEL,
	time: NO_DATA_LABEL,
	modalities: NO_DATA_LABEL,
	description: NO_DATA_LABEL,
};

export const getAppointmentTime = date =>
	new Date(date)
		.toLocaleString('en-us', {
			hour: 'numeric',
			minute: 'numeric',
			hour12: true,
		})
		.toLowerCase();

export const prepareTimeValue = value => {
	return value ? getAppointmentTime(value) : NO_DATA_LABEL;
};

export const prepareDateValue = (value, params) => {
	return value ? new Date(value).toLocaleDateString('en-us', params).replace(/,/g, '') : NO_DATA_LABEL;
};

export const preparePatientAppointmentDetailsInfo = appointment => {
	if (!appointment) return DEFAULT_VALUES;

	const { status, description } = appointment;

	const weekday = prepareDateValue(appointment?.start, { weekday: 'short' });
	const month = prepareDateValue(appointment?.start, { month: 'short' });
	const day = prepareDateValue(appointment?.start, { day: 'numeric' });

	const start = prepareTimeValue(appointment?.start);
	const end = prepareTimeValue(appointment?.end);
	const time = `${start}  -  ${end}`;

	const modalities =
		appointment?.extension
			?.find(({ url }) => url === fhirExtensionUrls.common.modality)
			?.extension?.map(({ valueCoding }) => valueCoding?.code)
			?.join(' | ') ?? NO_DATA_LABEL;

	return {
		time,
		modalities,
		status,
		month,
		day,
		weekday,
		description: description || NO_DATA_LABEL,
	};
};
