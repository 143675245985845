// @flow

// core
import React, { type Node, useCallback, useEffect, useMemo, useState } from 'react';
// components
import WorklistSpeedDial from '@rs-ui/components/WorklistSpeedDial/WorklistSpeedDial';
import WorklistDrawer from '@worklist-2/ui/src/components/Drawers/WorklistDrawer';
// libs
import _find from 'lodash/find';
// utils
import { useAuth } from '@worklist-2/core/src';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

interface IWorklistSpeedDialWithDrawerProps {
	currWorklistId?: string;
	showSaveBtn?: boolean;
	globalDefaultWorklist: any;
	/**
	 * List of fetched worklists
	 * @default []
	 */
	worklists?: any[];
	sort?: any[];
	fetchData: (page: number, user: any) => void;
	reloadWorklistData: () => void;
	onDeleteWorklist: (id: string) => void;
	onModifyWorklist: (id: string, payload: any) => void;
	onSaveClicked: () => void;
	onSubmitWorklist: (payload: any) => void;
	onUpdateWorklist: (id: string, payload: any) => void;
	onWorklistSelected: (worklist: any) => void;
}

export const WorklistSpeedDialWithDrawer = ({
	currWorklistId,
	showSaveBtn,
	globalDefaultWorklist,
	worklists = [],
	fetchData,
	reloadWorklistData,
	onDeleteWorklist,
	onModifyWorklist,
	onSaveClicked,
	onSubmitWorklist,
	onUpdateWorklist,
	onWorklistSelected,
}: IWorklistSpeedDialWithDrawerProps): Node => {
	// #region ==================== State ====================
	const [isNewWorklist, setIsNewWorklist] = useState(false);
	const [canEditRoleWorklist, setCanEditRoleWorklist] = useState(false);
	const [worklistSettingsDrawerOpen, setWorklistSettingsDrawerOpen] = useState(false);
	// #endregion

	// ==================== Hooks ====================
	const { getGuiRole, globalPermissionsForLoggedUser, hasPermissionByOrganizationAsync } = useAuth();

	// #region ==================== Events ====================
	const onAddClicked = useCallback(() => {
		setWorklistSettingsDrawerOpen(true);
		setIsNewWorklist(true);
	}, []);

	const onSettingClicked = useCallback(() => {
		setWorklistSettingsDrawerOpen(true);
		setIsNewWorklist(false);
	}, []);

	const _onSubmitWorklist = useCallback(
		(payload: any, keepDrawerOpen: boolean) => {
			if (!keepDrawerOpen) {
				setWorklistSettingsDrawerOpen(false);
			}
			return onSubmitWorklist(payload);
		},
		[setWorklistSettingsDrawerOpen, onSubmitWorklist]
	);

	const _onUpdateWorklist = useCallback(
		(id: string, payload: any, keepDrawerOpen: boolean) => {
			if (!keepDrawerOpen) {
				setWorklistSettingsDrawerOpen(false);
			}
			return onUpdateWorklist(id, payload);
		},
		[setWorklistSettingsDrawerOpen, onUpdateWorklist]
	);

	// #endregion

	const crossGetPrivilegeOnDemand = useBooleanFlagValue('cross-get-privilege-on-demand');

	const permissions = useMemo(() => {
		if (crossGetPrivilegeOnDemand) {
			return [];
		}
		return getGuiRole('Home');
	}, [getGuiRole, crossGetPrivilegeOnDemand]);

	const canViewSettings = useMemo(() => {
		if (crossGetPrivilegeOnDemand) {
			return globalPermissionsForLoggedUser?.UserWorklist;
		}
		return Boolean(_find(permissions, org => org.worklist?.['user worklist']?.read));
	}, [permissions, crossGetPrivilegeOnDemand, globalPermissionsForLoggedUser]);

	const currentWorklist = worklists?.find(i => i?.id === currWorklistId);
	const isCurrentWorklistAUserWorklist = currentWorklist?.practitioner;

	useEffect(() => {
		(async () => {
			if (crossGetPrivilegeOnDemand) {
				const orgPermission = currentWorklist?.organization?.id
					? await hasPermissionByOrganizationAsync(
							currentWorklist?.organization?.id,
							'Home',
							'worklist.role worklist',
							'update'
					  )
					: false;
				setCanEditRoleWorklist(orgPermission);
			} else {
				const orgPermission = permissions[currentWorklist?.organization?.id];
				if (orgPermission) {
					setCanEditRoleWorklist(orgPermission?.worklist?.['role worklist']?.update ?? false);
				}
			}
		})();
	}, [permissions, crossGetPrivilegeOnDemand]);

	return (
		<>
			<WorklistSpeedDial
				currWorklistId={currWorklistId}
				maxPages={6}
				pageSize={7}
				showSaveBtn={showSaveBtn}
				worklists={worklists}
				x={10}
				y={-10}
				onAddClicked={onAddClicked}
				onSaveClicked={
					canViewSettings &&
					(isCurrentWorklistAUserWorklist || (currentWorklist?.role && canEditRoleWorklist))
						? onSaveClicked
						: null
				}
				onSettingsClicked={
					isCurrentWorklistAUserWorklist || (currentWorklist?.role && canEditRoleWorklist)
						? onSettingClicked
						: null
				}
				onWorklistSelected={onWorklistSelected}
			/>

			<WorklistDrawer
				currWorklistId={currWorklistId}
				currentWorklist={currentWorklist}
				defaultColumns={!worklists?.length ? globalDefaultWorklist?.columns : []}
				drawerOpen={worklistSettingsDrawerOpen}
				enableUserWorklistSettings={canViewSettings}
				fetchData={fetchData}
				isNew={isNewWorklist}
				permissions={permissions}
				reloadWorklistData={reloadWorklistData}
				setDrawerOpen={setWorklistSettingsDrawerOpen}
				setIsNewWorklist={setIsNewWorklist}
				onDeleteWorklist={onDeleteWorklist}
				onModifyWorklist={onModifyWorklist}
				onSubmitWorklist={_onSubmitWorklist}
				onUpdateWorklist={_onUpdateWorklist}
			/>
		</>
	);
};
