import { Enums, ramsoft, EVENTS, cache } from '@cornerstonejs/core';
import { DOMParser as DOMParserTiptap } from 'prosemirror-model';
import { annotation } from '@cornerstonejs/tools';
import { generatePreview } from './previewGenerator';
import isValidMeasurement from '../contexts/utils/isValidMeasurement';
import { SERIES, SERIES_FRAME, KEY_IMAGE, SNAPSHOT_CONFIG } from '../consts/dragDropRules';
import cornerstoneWADOImageLoader from '@cornerstonejs/dicom-image-loader';
import initCornerstoneWadoImageLoader from '../cornerstoneWADOImageLoader/initCornerstoneWadoImageLoader';
import initVolumeLoader from '../cornerstone/initVolumeLoader';
import initCornerstoneTools from '../cornerstoneTools/initCornerstoneTools';
import addCornerstoneTools from '../cornerstoneTools/addCornerstoneTools';
import initCornerstone from '../cornerstone/initCornerstone';
import { MAX_CACHE_SIZE_1GB } from '../hooks/useInitCornerstoneLibraries';
import getDefaultRenderingEngine from '../cornerstone/getDefaultRenderingEngine';
import createDefaultRenderingEngine from '../cornerstone/createDefaultRenderingEngine';
import getViewportToolGroup from '../features/StackViewports/toolGroups/getViewportToolGroup';
import createViewportToolGroup from '../features/StackViewports/toolGroups/createViewportToolGroup';

export const DRAGGABLE_SERIES_INSTANCES = [KEY_IMAGE, SERIES, SERIES_FRAME];

const renderingEngineId = 'cornerstone3d-snapshot';
const INSTANCES_AVAILABLE_TO_RENDER = [KEY_IMAGE, SERIES_FRAME];
const { ViewportType } = Enums;

const initCornerstoneLibraries = async ({
	wonIvForceGpuRenderingRef,
	wonIvHardwareconRef,
	ivWonIncreasestorageRef,
}) => {
	window.ImageViewer3DInitialized = true;

	await initCornerstone({ wonIvForceGpuRenderingRef });
	initCornerstoneWadoImageLoader({ wonIvHardwareconRef });
	initVolumeLoader();
	initCornerstoneTools();
	addCornerstoneTools();

	if (ivWonIncreasestorageRef.current) {
		cache.setMaxCacheSize(MAX_CACHE_SIZE_1GB * 4);
	} else {
		cache.setMaxCacheSize(MAX_CACHE_SIZE_1GB);
	}
};

const configurateLoader = ({ accessToken, managingOrganizationId, studyUid }) => {
	cornerstoneWADOImageLoader.configure({
		beforeSend: () => ({
			authorization: accessToken,
			'ramsoft-internalmanagingorganizationid': managingOrganizationId,
			StudyInstanceUID: studyUid,
		}),
		useWebWorkers: true,
		decodeConfig: {
			convertFloatPixelDataToInt: false,
		},
	});
};

export const exportSnapshot = async ({
	imageId,
	metadata,
	measurements,
	config = SNAPSHOT_CONFIG,
	accessToken,
	managingOrganizationId,
	studyUid,
	wonIvForceGpuRendering,
	wonIvHardwarecon,
	ivWonIncreasestorage,
}) => {
	const { imageSize } = config;
	const preview = document.createElement('div');
	preview.style.width = `${200}px`;
	preview.style.height = `${200}px`;
	preview.style.position = 'absolute';
	preview.style.top = '0';
	preview.style.left = '-100%';
	preview.setAttribute('data-testid', 'snapshot-preview');

	document.body.appendChild(preview);
	const viewportId = `renderViewport-${imageId}`;
	let renderingEngine;

	if (window.ImageViewer3DInitialized) {
		renderingEngine =
			getDefaultRenderingEngine(renderingEngineId) || createDefaultRenderingEngine(renderingEngineId);
	} else {
		configurateLoader({ accessToken, managingOrganizationId, studyUid });
		await initCornerstoneLibraries({
			wonIvForceGpuRenderingRef: { current: wonIvForceGpuRendering },
			wonIvHardwareconRef: { current: wonIvHardwarecon },
			ivWonIncreasestorageRef: { current: ivWonIncreasestorage },
		});

		renderingEngine = createDefaultRenderingEngine(renderingEngineId);
	}
	metadata && cornerstoneWADOImageLoader.wadors.metaDataManager.add(imageId, metadata);
	renderingEngine.enableElement({
		viewportId,
		element: preview,
		type: ViewportType.STACK,
		defaultOptions: {
			suppressEvents: false,
		},
	});

	let toolGroup = getViewportToolGroup(renderingEngineId);

	if (!toolGroup) {
		createViewportToolGroup({ viewportId: renderingEngineId });

		toolGroup = getViewportToolGroup(renderingEngineId);
	}

	toolGroup.addViewport(viewportId, renderingEngineId);

	const viewport = renderingEngine.getViewport(viewportId);

	return new Promise(async resolve => {
		let elementRendered = false;
		const onImageRendered = () => {
			if (elementRendered) {
				return;
			}
			elementRendered = true;
			preview.removeEventListener(EVENTS.IMAGE_RENDERED, onImageRendered);

			if (measurements?.length > 0) {
				measurements?.map(measurement => {
					const defaultAnnotationManager = annotation.state.getAnnotationManager();
					defaultAnnotationManager.addAnnotation({ ...measurement, highlighted: false });
				});
				renderingEngine.render();
			}

			const imageData = viewport.getImageData();
			const { dimensions } = imageData;
			const width = dimensions?.[0];
			const height = dimensions?.[1];
			const aspectRatio = width / height;
			preview.style.width = `${imageSize}px`;
			preview.style.height = `${imageSize / aspectRatio}px`;
			ramsoft.resize({ renderingEngineId });

			setTimeout(async () => {
				const imagePreview = await generatePreview(preview);
				renderingEngine.disableElement(viewportId);
				preview.remove();
				renderingEngine.destroy();
				resolve({ imagePreview, aspectRatio });
			}, 100);
		};
		preview.addEventListener(EVENTS.IMAGE_RENDERED, onImageRendered);
		await viewport.setStack([imageId]);
	});
};

export const handleDnDSeries = async ({
	e,
	mainEditorRef,
	accessToken,
	wonIvForceGpuRendering,
	ivWonIncreasestorage,
	wonIvHardwarecon,
	galaxyDvKeyImageFitInTable,
}) => {
	try {
		const jsonData = JSON.parse(e.dataTransfer.getData('text/plain'));
		if (DRAGGABLE_SERIES_INSTANCES.includes(jsonData?.type)) {
			e.preventDefault();
			if (INSTANCES_AVAILABLE_TO_RENDER.includes(jsonData.type)) {
				const { config, imageId, measurements, managingOrganizationId, studyUid, metadata } = jsonData;
				const { view } = mainEditorRef.current;

				const coordinates = view.posAtCoords({
					left: e.clientX,
					top: e.clientY,
				});

				if (imageId) {
					const { imagePreview, aspectRatio } = await exportSnapshot({
						imageId,
						measurements,
						metadata,
						config,
						accessToken,
						studyUid,
						managingOrganizationId,
						wonIvForceGpuRendering,
						wonIvHardwarecon,
						ivWonIncreasestorage,
					});
					if (imagePreview) {
						const keyImage = document.createElement('p');
						keyImage.innerHTML = `<img src=${imagePreview?.toDataURL(
							config.mimeType
						)} width=${200}px height=${200 / aspectRatio}px />`;
						const parsedContent = DOMParserTiptap.fromSchema(view.state.schema).parseSlice(keyImage);
						const { tr } = view.state;
						if (galaxyDvKeyImageFitInTable) {
							const { pos } = view.state.selection.$anchor;
							tr.insert(pos, parsedContent.content);
						} else {
							tr.insert(coordinates.pos, parsedContent.content);
						}

						view.dispatch(tr);
					}
				}
			}
		}
	} catch (error) {
		console.log('not a key image');
	}
};

export const findImageIdMeasurements = (imageId, measurements) =>
	measurements.filter(measurement => {
		if (isValidMeasurement(measurement)) {
			const referencedImageId = measurement.metadata?.referencedImageId;
			return imageId === referencedImageId;
		}
		return false;
	});
