import React, { createContext, useContext, useEffect } from 'react';
import { utilities, eventTarget, triggerEvent, ramsoft } from '@cornerstonejs/core';
import { ToolGroupManager } from '@cornerstonejs/tools';
import { MouseBindings } from '@cornerstonejs/tools/dist/esm/enums';
import getDefaultRenderingEngine from '../cornerstone/getDefaultRenderingEngine';
import { CORNERSTONE_EVENTS } from './consts/consts';
import { debounce } from 'lodash';
import { logDebug } from '@worklist-2/core/src';

const ImageViewerCornerstoneContext = createContext({});

const renderingEngineId = 'cornerstone3d';

const shadowActiveViewportId = 'SHADOW_ACTIVE_VIEWPORT_ID';

const ImageViewerCornerstoneContextProvider = ({ children }) => {
	const changeActiveTool = ({ activeTool }) => {
		if (!activeTool) {
			return;
		}

		const toolGroups = ToolGroupManager.getAllToolGroups();

		toolGroups?.forEach(toolGroup => {
			if (!toolGroup) {
				return;
			}

			if (!toolGroup._toolInstances?.[activeTool]) {
				disableMousePrimaryTools(toolGroup);
				return;
			}

			disableMousePrimaryTools(toolGroup);

			toolGroup.setToolActive(activeTool, {
				bindings: [{ mouseButton: MouseBindings.Primary }],
			});
		});
	};

	const disableMousePrimaryTools = toolGroup => {
		const { toolOptions } = toolGroup;

		const element = document.getElementById(toolGroup?.viewportsInfo?.[0]?.viewportId);
		// reset cursor style to default
		if (element) {
			element.style.cursor = 'default';
		}

		for (const toolName in toolOptions) {
			if (toolOptions[toolName].bindings.find(binding => binding.mouseButton === MouseBindings.Primary)) {
				toolGroup.setToolPassive(toolName);
			}
		}
	};

	const resizeViewports = debounce(() => {
		const renderingEngine = getDefaultRenderingEngine();

		if (!renderingEngine) {
			return;
		}

		const viewports = renderingEngine.getViewports();

		if (viewports?.length === 0) return;

		viewports.forEach(viewport => (viewport.suppressEvents = true));

		ramsoft.resize({ renderingEngineId });

		viewports.forEach(viewport => (viewport.suppressEvents = false));
	}, 100);

	const viewportCustomTool = ({ viewportId, toolName, options = {} }) => {
		const renderingEngine = getDefaultRenderingEngine();

		if (!renderingEngine) {
			return;
		}

		const renderingEngineViewport = renderingEngine.getViewport(viewportId);

		if (!renderingEngineViewport) {
			return;
		}
		const { rotation } = renderingEngineViewport.getProperties();

		switch (toolName) {
			case 'RotateLeft':
				renderingEngineViewport.setProperties({
					rotation: rotation - 90,
				});
				break;
			case 'RotateRight':
				renderingEngineViewport.setProperties({
					rotation: rotation + 90,
				});
				break;
			case 'Invert':
				const { invert } = renderingEngineViewport.getProperties();
				renderingEngineViewport.setProperties({
					invert: !invert,
				});
				triggerEvent(renderingEngineViewport.element, CORNERSTONE_EVENTS.CORNERSTONE_INVERT_MODIFIED, {
					viewportId,
					viewport: renderingEngineViewport,
					element: renderingEngineViewport.element,
				});
				break;
			case 'WindowLeveling':
				renderingEngineViewport.setProperties({
					voiRange: utilities.windowLevel.toLowHighRange(options.windowWidth, options.windowCenter),
				});
				break;
			case 'FlipVertically':
				const { flipVertical } = renderingEngineViewport.getCamera();
				renderingEngineViewport.setCamera({ flipVertical: !flipVertical });
				triggerEvent(renderingEngineViewport.element, CORNERSTONE_EVENTS.CORNERSTONE_FLIP_MODIFIED, {
					viewportId,
					viewport: renderingEngineViewport,
					element: renderingEngineViewport.element,
				});
				break;
			case 'FlipHorizontally':
				const { flipHorizontal } = renderingEngineViewport.getCamera();
				renderingEngineViewport.setCamera({
					flipHorizontal: !flipHorizontal,
				});
				triggerEvent(renderingEngineViewport.element, CORNERSTONE_EVENTS.CORNERSTONE_FLIP_MODIFIED, {
					viewportId,
					viewport: renderingEngineViewport,
					element: renderingEngineViewport.element,
				});
				break;
			default:
				break;
		}

		renderingEngineViewport.render();
	};

	const setViewportImageIdIndex = ({ viewportId, imageIdIndex }) => {
		const renderingEngine = getDefaultRenderingEngine();

		if (!renderingEngine) {
			return;
		}

		const renderingEngineViewport = renderingEngine.getViewport(viewportId);

		if (!renderingEngineViewport) {
			return;
		}

		renderingEngineViewport.setImageIdIndex(imageIdIndex);
		renderingEngineViewport.targetImageIdIndex = imageIdIndex;
	};

	const scrollViewport = ({ viewportId, delta }) => {
		const renderingEngine = getDefaultRenderingEngine();

		if (!renderingEngine) {
			return;
		}

		const renderingEngineViewport = renderingEngine.getViewport(viewportId);

		if (!renderingEngineViewport) {
			return;
		}

		renderingEngineViewport.scroll(delta);
	};

	const isImageDisplayedInViewport = referencedImageId => {
		let result = false;

		const renderingEngine = getDefaultRenderingEngine();

		if (!renderingEngine) {
			return;
		}

		const cornerstoneViewports = renderingEngine.getViewports();

		cornerstoneViewports?.forEach(cornerstoneViewport => {
			const imageId = cornerstoneViewport?.getCurrentImageId();

			if (imageId === referencedImageId) {
				result = true;
			}
		});

		return result;
	};

	const ON_CORNERSTONE_IMAGE_LOADED = e => {
		logDebug('IV', `Image ${e.detail?.image?.imageId} decoded in ${e.detail?.image?.decodeTimeInMS}ms`);
	};

	useEffect(() => {
		eventTarget.addEventListener('CORNERSTONE_IMAGE_LOADED', ON_CORNERSTONE_IMAGE_LOADED);

		return () => {
			eventTarget.removeEventListener('CORNERSTONE_IMAGE_LOADED', ON_CORNERSTONE_IMAGE_LOADED);
		};
	}, []);

	return (
		<ImageViewerCornerstoneContext.Provider
			value={{
				changeActiveTool,
				resizeViewports,
				viewportCustomTool,
				setViewportImageIdIndex,
				scrollViewport,
				disableMousePrimaryTools,
				isImageDisplayedInViewport,
			}}
		>
			{children}
		</ImageViewerCornerstoneContext.Provider>
	);
};

const useImageViewerCornerstoneContext = () => useContext(ImageViewerCornerstoneContext);

export {
	useImageViewerCornerstoneContext,
	ImageViewerCornerstoneContext,
	ImageViewerCornerstoneContextProvider,
	renderingEngineId,
	shadowActiveViewportId,
};
