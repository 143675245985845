import { useEffect, useRef, useState, useMemo } from 'react';
import { eventTarget, Enums, utilities, EVENTS, getEnabledElementByIds, metaData } from '@cornerstonejs/core';
import getDefaultRenderingEngine from '../../cornerstone/getDefaultRenderingEngine';
import { utilities as toolsUtilities, ScaleOverlayTool } from '@cornerstonejs/tools';
import { renderingEngineId } from '../../contexts/ImageViewerCornerstoneContext';
import { useImageViewerStudiesContext } from '../../contexts/ImageViewerStudiesContext';
import { useImageViewerLayoutContext } from '../../contexts/ImageViewerLayoutContext';
import { getInstanceModality } from '../../utils/utils';
import { useApplyHPRulesToViewport } from '../../contexts/helpers/applyHPRulesToViewport';
import { assignRule } from '../../contexts/utils/scaleUtils';
import { htj2kMixedOptions } from '../../cornerstone/initCornerstone';
import useStateRef from '../../hooks/useStateRef';
import fixMGOrientation from '../../utils/fixMGImageOrientation';
import { useImageViewerWheelContext } from '../../contexts/ImageViewerWheelContext';
import { getImageLaterality } from '../../components/ImageViewerViewport/utils/getImageLaterality';
import createViewportToolGroup from './toolGroups/createViewportToolGroup';
import getSeriesOrientationPlane, { DICOM_IMAGE_PLANE_ANNOTATIONS } from '../../utils/getSeriesOrientationPlane';
import getMiddleFrameIndex from '../../utils/getMiddleFrameIndex';
import useOnSetActiveTool from '../../hooks/useOnSetActiveTool';
import { useOverlayStore } from '../../contexts/OverlayContext';
import { getColor } from '../../components/ImageViewerViewport/ImageViewerViewportCustomOverlay';
import cornerstoneWADOImageLoader from '@cornerstonejs/dicom-image-loader';
import { useCalibrationToolContext } from '@rs-ui/views/ImageViewerView3D/features/CalibrationTool/CalibrationToolContext';
import { ApplyAutoWindowLevelToViewport } from '../CustomToolbar/components/Tools/WindowLevel/AutoWindowLevel';
import { applyVOILUTFunction, isSigmoidModality } from '../WindowLevel/Sigmoid';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';
import { useImageViewerWindowLevelContext } from '../../contexts/ImageViewerWindowLevelContext';
import { logDebug } from '@worklist-2/core/src';
import resetScaleOverlay from '../../cornerstoneTools/utils/resetScaleOverlay';
import { useImageViewerView3DContext } from '../../contexts/ImageViewerView3DContext';
import { ResourceTypes, useBookmarksStore } from '../../hooks/useBookmark';
import { useSearchParams } from 'react-router-dom';
import { useConfig } from '@rs-core/hooks';

const LOADING_STATES = {
	ready: 'ready',
	failed: 'failed',
	error: 'error',
};

export const getImageIds = ({ siblingViewports, viewportIndex, series, isKeyImage, keyImageIndex }) => {
	if (isKeyImage) {
		return [series.imageIds[keyImageIndex]];
	}

	let imageIds = [...series.imageIds];

	if (siblingViewports.length >= imageIds.length) {
		imageIds = imageIds.length - 1 >= viewportIndex ? [imageIds[viewportIndex]] : [];
	} else {
		imageIds.splice(0, viewportIndex);
		const maxNum = siblingViewports.length - 1;
		imageIds.splice(-(maxNum - viewportIndex), maxNum - viewportIndex);
	}

	return imageIds;
};

export const applyAutoWindowLevelIfTagsMissing = (viewportId, metadata, viewport, defaultWindowLevelRef) => {
	const windowCenter = metadata?.['00281050']?.Value?.[0];
	const windowWidth = metadata?.['00281051']?.Value?.[0];

	if (!windowCenter || !windowWidth) {
		const { lower, upper } = viewport?.voiRange || {};
		defaultWindowLevelRef.current = utilities.windowLevel.toWindowLevel(Math.round(lower), Math.round(upper));
		ApplyAutoWindowLevelToViewport(viewportId, false);
	}
};

export const applySigmoidIfCapable = (viewportId, metadata) => {
	const modality = getInstanceModality(metadata)?.toUpperCase();
	const voilutFunction = metadata?.['00281056']?.Value?.[0];

	if (isSigmoidModality(modality) && voilutFunction?.toUpperCase() === 'SIGMOID') {
		applyVOILUTFunction(viewportId, Enums.VOILUTFunctionType.SAMPLED_SIGMOID, false);
	}
};

export const setViewportDetials = (viewState, viewport) => {
	if (viewState?.isLoaded) {
		const currentViewportDetail = viewState?.viewportDetails?.find(x => x.viewportId == viewport.id);

		if (currentViewportDetail) {
			viewport.setCamera({
				flipVertical: currentViewportDetail.camera.flipVertical,
				flipHorizontal: currentViewportDetail.camera.flipHorizontal,
			});
			viewport.setPan(currentViewportDetail.pan);
			viewport.setRotation(currentViewportDetail.rotation);
			viewport.setVOI(currentViewportDetail.voiRange);
		}
	}
};

const useAddOnViewportEnabled = () => {
	const { createCine, activeToolRef } = useImageViewerWheelContext();
	const { isShownCalibrationRuler } = useCalibrationToolContext();

	const wonIvHtj2Kpl = useBooleanFlagValue('WON-IV-HTJ2KPL');
	const wonIvFixrotationflip = useBooleanFlagValue('WON-IV-FIXROTATIONFLIP');
	const wonIvCalibrationruler = useBooleanFlagValue('WON-IV-CALIBRATIONRULER');
	const wonIvShuttertool = useBooleanFlagValue('WON-IV-SHUTTERTOOL');
	const wonIvQuadzoom = useBooleanFlagValue('WON-IV-Quadzoom');
	const wonIvCalibrationTool = useBooleanFlagValue('won-iv-calibration-tool');
	const wonIvAutoWindowLevel = useBooleanFlagValue('won-iv-auto-window-level');
	const wonIvSigmoid = useBooleanFlagValue('won-iv-sigmoid');
	const wonIvPrBookmark = useBooleanFlagValue('WON-IV-PR-BOOKMARK');

	const { seriesRef, internalStudyId } = useImageViewerStudiesContext();
	const { isMPRViewRef, isFusionViewRef } = useImageViewerLayoutContext();
	const { defaultWindowLevelRef } = useImageViewerWindowLevelContext();
	const overlayStore = useOverlayStore();
	const { applyHPRulesToViewport } = useApplyHPRulesToViewport();
	const { imageLoaded, setImageLoaded } = useImageViewerView3DContext();

	const [initialized, setInitialized] = useState(false);

	const viewportElRef = useStateRef(null);
	const isShownCalibrationRulerRef = useRef(isShownCalibrationRuler);
	isShownCalibrationRulerRef.current = isShownCalibrationRuler;
	const wonIvHtj2KplRef = useRef(wonIvHtj2Kpl);
	wonIvHtj2KplRef.current = wonIvHtj2Kpl;
	const wonIvCalibrationrulerRef = useRef(wonIvCalibrationruler);
	wonIvCalibrationrulerRef.current = wonIvCalibrationruler;
	const wonIvShuttertoolRef = useRef(wonIvShuttertool);
	wonIvShuttertoolRef.current = wonIvShuttertool;
	const wonIvQuadzoomRef = useRef(wonIvQuadzoom);
	wonIvQuadzoomRef.current = wonIvQuadzoom;
	const wonIvCalibrationToolRef = useRef(wonIvCalibrationTool);
	wonIvCalibrationToolRef.current = wonIvCalibrationTool;

	const [searchParams] = useSearchParams();
	const dataInternalStudyId = useMemo(
		() => searchParams.get('StudyId') || internalStudyId,
		[internalStudyId, searchParams]
	);

	const studyID = dataInternalStudyId;
	const resourceType = ResourceTypes.viewState;

	const __config = useConfig();
	const prefixUrl = __config.data_sources.fhir;
	const url = `${prefixUrl}/ImagingStudy/bookmark?studyID=${studyID}&resourceType=${resourceType}`;

	const getBookmark = useBookmarksStore(state => state.getBookmark);
	const fetchResource = useBookmarksStore(state => state.fetchResource);

	const applyViewportDetials = async viewport => {
		let bookmark = getBookmark({ studyID });
		let viewState = bookmark?.resources?.find(r => r.resourceType === ResourceTypes.viewState);

		if (!viewState?.isLoaded) {
			await fetchResource({ studyID, resourceType, url });
			bookmark = getBookmark({ studyID });
			viewState = bookmark?.resources?.find(r => r.resourceType === ResourceTypes.viewState);
		}

		setViewportDetials(viewState, viewport);
	};

	const { onSetActiveTool } = useOnSetActiveTool();

	const onElementEnabled = async event => {
		if (isMPRViewRef.current || isFusionViewRef.current) {
			return;
		}

		const renderingEngine = getDefaultRenderingEngine();

		if (!renderingEngine) {
			return;
		}

		const { viewportId } = event.detail;

		const viewport = renderingEngine.getViewport(viewportId);

		if (!viewport?.defaultOptions) {
			return;
		}

		const { element } = viewport;

		if (!element) {
			return;
		}

		viewportElRef.current = element;

		const { isKeyImage } = viewport.defaultOptions;
		const { keyImageIndex } = viewport.defaultOptions;
		const initialInstanceIndex = isKeyImage ? 0 : viewport.defaultOptions.initialInstanceIndex || 0;

		const series = seriesRef.current.find(item => {
			const sameSeries = item.uniqueId === viewport.defaultOptions?.seriesId;
			let sameStudy = true;
			let sameManagingOrg = true;

			if (viewport.defaultOptions?.studyInstanceUID !== undefined) {
				sameStudy = viewport.defaultOptions.studyInstanceUID === item.studyInstanceUID;
			}

			if (viewport.defaultOptions?.managingOrganizationId !== undefined) {
				sameManagingOrg = viewport.defaultOptions.managingOrganizationId === item.managingOrganizationId;
			}

			return sameSeries && sameStudy && sameManagingOrg;
		});

		if (!series?.imageIds?.length) {
			return;
		}

		const imageIds = getImageIds({
			siblingViewports: viewport.defaultOptions.siblingViewports,
			viewportIndex: viewport.defaultOptions.viewportIndex,
			series,
			isKeyImage,
			keyImageIndex,
		});

		if (!imageIds.length) {
			return;
		}

		const middleFrame = getMiddleFrameIndex(imageIds);
		const metadata =
			cornerstoneWADOImageLoader.wadors.metaDataManager.get(imageIds[middleFrame]) ||
			cornerstoneWADOImageLoader.wadors.metaDataManager.get(imageIds[0]);
		const modality = getInstanceModality(metadata)?.toUpperCase();
		const laterality = getImageLaterality(metadata, series);
		const plane = DICOM_IMAGE_PLANE_ANNOTATIONS[getSeriesOrientationPlane(metadata)];
		const imagePlaneModule = metaData.get('imagePlaneModule', imageIds[0]);
		const pixelSpacing = imagePlaneModule?.pixelSpacing;
		const toolGroup = createViewportToolGroup({
			viewportId,
			modality,
			plane,
			imageIds,
			wonIvShuttertoolRef,
			wonIvQuadzoomRef,
			wonIvCalibrationToolRef,
			pixelSpacing,
		});

		if (!toolGroup) return;

		if (!getEnabledElementByIds(viewportId, renderingEngineId)) {
			return;
		}

		logDebug('IV::useAddOnViewportEnabled', 'onElementEnabled - assign images to viewport', {
			timestamp: Date.now(),
			imageId: imageIds[0],
		});

		viewport
			.setStack(imageIds, initialInstanceIndex, wonIvHtj2KplRef.current ? htj2kMixedOptions : undefined)
			.then(async () => {
				if (!getEnabledElementByIds(viewportId, renderingEngineId)) {
					return;
				}

				toolsUtilities.stackPrefetch.setConfiguration({
					maxImagesToPrefetch: 20,
					preserveExistingPool: false,
				});

				toolsUtilities.stackPrefetch.enable(element);

				applyHPRulesToViewport({ viewport });

				const onImageRendered = () => {
					const shouldCalibrationToolHidden = !['HC', 'SC', 'DOC'].includes(modality);
					const shouldAddScaleOverlayTool =
						wonIvCalibrationrulerRef.current &&
						shouldCalibrationToolHidden &&
						['CT', 'MR', 'US', 'DX', 'CR', 'MG', 'DP', 'RF', 'XA'].includes(modality);

					logDebug('IV::useAddOnViewportEnabled', `onImageRendered executed at viewport load`, {
						timestamp: Date.now(),
						shouldAddScaleOverlayTool,
					});

					// to delay the rendering of the scale overlay tool
					setTimeout(async () => {
						if (shouldAddScaleOverlayTool && toolGroup) {
							let color;
							const { profile, customOverlays } = overlayStore.getState();

							if (customOverlays?.[profile]?.color) {
								color = getColor(customOverlays[profile].color);
							}
							toolGroup.addTool(ScaleOverlayTool.toolName);
							toolGroup.setToolConfiguration(
								ScaleOverlayTool.toolName,
								{
									viewportId,
									color,
									scaleLocation: laterality === 'R' ? 'left' : 'right',
								},
								true
							);

							if (isShownCalibrationRulerRef.current) {
								toolGroup.setToolEnabled(ScaleOverlayTool.toolName);
							}

							// to re-render the scale overlay tool
							resetScaleOverlay(viewport, viewportId);
						}
					}, 800);

					// to remove the event listener after image is rendered
					element.removeEventListener(EVENTS.IMAGE_RENDERED, onImageRendered);
				};

				// to add scale overlay tool after image is rendered
				element.addEventListener(EVENTS.IMAGE_RENDERED, onImageRendered);

				if (wonIvAutoWindowLevel) {
					applyAutoWindowLevelIfTagsMissing(viewportId, metadata, viewport, defaultWindowLevelRef);
				}

				if (wonIvSigmoid) {
					applySigmoidIfCapable(viewportId, metadata);
				}

				if (wonIvPrBookmark) {
					await applyViewportDetials(viewport);
				}
			});

		toolGroup.addViewport(viewport.id, renderingEngineId);

		if (modality === 'HC' || modality === 'US') {
			const windowCenter = metadata?.['00281050']?.Value?.[0];
			const windowWidth = metadata?.['00281051']?.Value?.[0];

			if (!windowCenter || !windowWidth) {
				const viewportProperties = viewport.getProperties();
				const { lower, upper } = utilities.windowLevel.toLowHighRange(256, 128);

				viewportProperties.voiRange = {
					upper,
					lower,
				};

				viewport.setProperties(viewportProperties);
			}
		}

		if (viewport.defaultOptions.defaultRules?.length > 0) {
			const toggleRule = viewport.defaultOptions.defaultRules.find(rule => rule.type === 'toggle');
			if (toggleRule?.values?.options?.cine) {
				createCine({ element });
			}
		}

		if (modality === 'MG' && laterality) {
			if (!viewport.defaultOptions.defaultRules?.length) {
				viewport.defaultOptions.defaultRules = [];
			}

			if (!viewport.defaultOptions.defaultRules.find(rule => rule.type === 'orientation')) {
				const patientOrientationValue = metadata?.['00200020']?.Value;
				const viewCode = series?.mammographyViewCode || metadata?.['00185101']?.Value?.[0];
				const newRule = {
					viewport,
					rule: {
						type: 'orientation',
						values: {},
					},
				};

				if (laterality === 'R') {
					newRule.rule.values.align = 'right';
				}

				if (laterality === 'L') {
					newRule.rule.values.align = 'left';
				}

				if (viewCode && patientOrientationValue) {
					if (wonIvFixrotationflip) {
						const rightPatientDirection = patientOrientationValue[0];
						const bottomPatientDirection = patientOrientationValue[1];
						const { rotation, flip } = fixMGOrientation(
							viewCode,
							laterality,
							rightPatientDirection,
							bottomPatientDirection
						);

						if (rotation) {
							newRule.rule.values[rotation] = true;
						}

						if (flip) {
							newRule.rule.values[flip] = true;
						}
					}
				}

				assignRule(newRule);
			}
		}

		const onStackNewImage = async e => {
			if (!getEnabledElementByIds(viewportId, renderingEngineId)) {
				return;
			}

			logDebug('IV::useAddOnViewportEnabled', 'onStackNewImage - Image loaded', {
				timestamp: Date.now(),
				imageId: e?.detail?.imageId,
				imageQualityStatus: e?.detail?.image?.imageQualityStatus,
			});

			setTimeout(() => {
				// Start loading remaining data when first image loaded
				// Add a delay to prevent loading data before the image is display completely
				if (!imageLoaded) {
					setImageLoaded(true);
				}
			}, 300);

			const isFullyLoaded = e?.detail?.image?.imageQualityStatus === Enums.ImageQualityStatus.FULL_RESOLUTION;
			if (isFullyLoaded) {
				element?.setAttribute(LOADING_STATES.ready, true);
				element?.removeAttribute(LOADING_STATES.error);
				element?.removeAttribute(LOADING_STATES.failed);
				element?.removeEventListener(EVENTS.STACK_NEW_IMAGE, onStackNewImage);
			}

			applyHPRulesToViewport({ viewport });

			if (wonIvPrBookmark) {
				await applyViewportDetials(viewport);
			}
		};

		eventTarget.addEventListener(EVENTS.IMAGE_LOAD_ERROR, onImageLoadError);
		eventTarget.addEventListener(EVENTS.IMAGE_LOAD_FAILED, onImageLoadFailed);
		element?.addEventListener(EVENTS.STACK_NEW_IMAGE, onStackNewImage);

		onSetActiveTool(activeToolRef.current, undefined, false, false);
	};

	const onImageLoadError = eventDetail => {
		if (!eventDetail?.cancelable) {
			viewportElRef.current?.setAttribute(LOADING_STATES.error, true);
			viewportElRef.current?.removeAttribute(LOADING_STATES.ready);
		}
	};

	const onImageLoadFailed = () => {
		viewportElRef.current?.setAttribute(LOADING_STATES.failed, true);
		viewportElRef.current?.removeAttribute(LOADING_STATES.ready);
	};

	useEffect(() => {
		eventTarget.addEventListener(EVENTS.ELEMENT_ENABLED, onElementEnabled);
		setInitialized(true);

		return () => {
			eventTarget.removeEventListener(EVENTS.ELEMENT_ENABLED, onElementEnabled);
			eventTarget.removeEventListener(EVENTS.IMAGE_LOAD_ERROR, onImageLoadError);
			eventTarget.removeEventListener(EVENTS.IMAGE_LOAD_FAILED, onImageLoadFailed);
		};
	}, []);

	return {
		initialized,
	};
};

export default useAddOnViewportEnabled;
