import React, { useContext, useState } from 'react';
import classnames from 'classnames';
import { searchScopes, faxIsValidToDisplayPDF } from '@worklist-2/core/src/index';
import { useTranslation } from 'react-i18next';
import { FaxContext } from '@rs-ui/context/FaxContext';
import RsGrid from '@rs-ui/components/Grid';

const FaxLogGrid = ({ className, name, columns, filters, setFilters }) => {
	const { t } = useTranslation('fax');
	const [sort, setSort] = useState([]);
	const { selectedFaxLog, setSelectedFaxLog, dataGridRefValue, setDataGridRefValue } = useContext(FaxContext);

	const handleRowSelect = rowData => {
		if (!rowData) return;

		const { id, messageId, faxDirection, faxDatetime, faxFrom, status } = rowData.rawData;
		const alreadySelected = selectedFaxLog?.id === rowData.rawData.id;
		const faxHasValidPDF = faxIsValidToDisplayPDF(rowData.rawData);

		if (faxHasValidPDF && !alreadySelected) {
			setSelectedFaxLog({
				id,
				messageId,
				faxDirection,
				faxDatetime,
				faxFrom,
				status,
				apiRawData: rowData.rawData,
			});
		} else {
			setSelectedFaxLog(null);
		}
	};

	return (
		<RsGrid
			isSingleSort
			selectableRows
			className={classnames(className)}
			columns={columns}
			enableRowDnd={false}
			fetchInterval={15000}
			filters={filters}
			name={name}
			scope={searchScopes.fax}
			setDataGridRefValue={setDataGridRefValue}
			setFilters={setFilters}
			setSort={setSort}
			sort={sort}
			title={t('Fax Log')}
			onRowSelect={handleRowSelect}
		/>
	);
};

export default FaxLogGrid;
