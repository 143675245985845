// core
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState, memo } from 'react';
import mergeRefs from 'react-merge-refs';
import useMeasure from 'react-use-measure';
import _ from 'lodash';
import { fhirExtensionUrls, useRouter } from '@worklist-2/core/src';
import { CSVLink } from 'react-csv';
import { utils, writeFile } from 'xlsx';
import getUserFullName from '@worklist-2/core/src/fhir/resource/columnMapping/utils/getUserFullName';
import { useTranslation } from 'react-i18next';

// MUI
import LinearProgress from '@mui/material/LinearProgress';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';

// Context
import { useRecognitionContext } from '@worklist-2/worklist/src/DocumentViewerV3/contexts/RecognitionContext';

// TanStack
import { createColumnHelper, flexRender, getCoreRowModel, useReactTable } from '@tanstack/react-table';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useVirtualizer, notUndefined } from '@tanstack/react-virtual';

// React DnD
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

// Components
import DataGridColumnHeaderV2, { MemoizedDataGridColumnHeaderV2 } from './DataGridColumnHeaderV2';
import DataGridRow, { MemoizedDataGridRow } from './DataGridRow';

// utils
import { clearDuplicates, findIdenticalItems, hasAtLeastOneNonIdenticalItem } from '../Worklist/WorklistGrid';
import { getViewerURLs } from '../Worklist/WorklistGrid/getViewerURLs';
import { getTextWidth } from '@rs-ui/helpers/utils';

// libraries
import PropTypes from 'prop-types';
import { useBooleanFlagValue } from '@rs-core/hooks/useFlags';

const DataGrid = forwardRef(
	(
		{
			name,
			cacheKey,
			columns,
			setCurrWorklistColumns,
			height,
			pageSize,
			canResizeColumns,
			selectedRow,
			enableRowDnd,
			enableColumnDnd,
			setTotalRecords,
			onRowClick,
			onLoadData,
			onLoadDataRef,
			selectedResources,
			shouldUpdateAfterFiltersChanged,
			onUpdateAfterFiltersChanged,
			columnSizing,
			setColumnSizing,
			cellsPadding,
			defaultColumnWidth,
			subRow,
			subRowSX,
			disableDataCache,
			expandedRowIndex,
			footer,
			cellSx,
			forceFixed,
			setEnableDownloadButton,
			visitedWorklist,
			gridCustomHeader,
			onScrollChange,
			scrollPosition,
		},
		ref
	) => {
		const { t } = useTranslation('root');
		const enableDataGridOptimization = useBooleanFlagValue('maven-data-grid-virtualization');

		//we need a reference to the scrolling element for logic down below
		const tableContainerRef = useRef(null);
		//Reference to the calculate position of progress bar
		const tableHeaderRef = useRef(null);
		// Ref for virtualized rows scroll div
		const vRowRef = useRef(null);

		// useMeasure requires its own seperate ref: https://www.npmjs.com/package/react-use-measure#multiple-refs
		const [tableMeasurementsRef, tableMeasurements] = useMeasure();
		const [hasMoreData, setHasMoreData] = useState(false);
		const [forceRefresh, setForceRefresh] = useState(false);
		const [lastColId, setLastColId] = useState('');
		const [lastColWidth, setLastColWidth] = useState(0);
		const [secondLastChildResizerOpacity, setSecondLastChildResizerOpacity] = useState(0);
		const { goTo } = useRouter();

		// stack of selected items at the top of the grid.
		// we should manage it here because we manage data-flow inside of this component
		const stickyStack = useRef(null);

		// use for managing downloading grid data
		const csvLinkRef = useRef();

		const [exportHeader, setExportHeader] = useState([]);
		const [exportData, setExportData] = useState([]);

		// Message shown when there is no data in the grid
		const [emptyMessage, setEmptyMessage] = useState(null);
		// Used to check if the component has rendered
		const [isRendered, setIsRendered] = useState(false);

		useEffect(() => {
			// Restore scroll position on mount
			if (isRendered && scrollPosition) {
				const currentRef = enableDataGridOptimization ? vRowRef : tableContainerRef;

				currentRef.current.scrollTop = scrollPosition.scrollTop;
				currentRef.current.scrollLeft = scrollPosition.scrollLeft;
			}
		}, [isRendered]);

		// Reset scroll position on cacheKey change (e.g. when the user changes filters or sorts)
		useEffect(() => {
			if (cacheKey) {
				const currentRef = enableDataGridOptimization ? vRowRef : tableContainerRef;

				currentRef.current.scrollTop = 0;
				currentRef.current.scrollLeft = 0;
			}
		}, [cacheKey]);

		const handleExport = fileType => {
			const { rows: exportedRows } = table.getRowModel();
			if (exportedRows?.length >= 0) {
				const physicianList = [
					'performingTechnologist',
					'readingPhysician',
					'referringPhysician',
					'transcriptionist',
				];
				// get csv header from columns, remove id and columsn without values
				const exportHeaders = columns
					.filter(c => !c.options.excludeFromSave)
					.map(c => ({ label: c.label, key: c.name }));

				// get values from _valuesCache
				const arr = [];

				exportedRows.forEach(row => {
					const arrObj = {};
					row.getVisibleCells().forEach((cell, index) => {
						if (cell.column.columnDef.visible && !cell.column.columnDef.options.excludeFromSave) {
							const useCustomExportFn = cell.column.columnDef.options?.useCustomExportFn;
							const cellObj = flexRender(cell.column.columnDef.cell, cell.getContext());
							arrObj[cellObj?.props?.column?.id] = useCustomExportFn
								? cell.column.accessorFn(cell.row.original, index, cell.column)
								: cellObj?.props?.cell?.getValue();
						}
					});

					// need to remove ^ from physician names
					physicianList.forEach(physician => {
						if (arrObj[physician]) {
							arrObj[physician] = getUserFullName(arrObj[physician]);
						}
					});
					arr.push(arrObj);
				});
				if (fileType?.toLowerCase() === 'csv') {
					// set Exportdata and Header will trigger the download in the useEffect below
					setExportHeader(exportHeaders);
					setExportData(arr);
				} else if (fileType?.toLowerCase() === 'xlsx') {
					const xlsxHeaders = [];
					exportHeaders.forEach(header => {
						xlsxHeaders.push(header.label);
					});
					const worksheet = utils.json_to_sheet(arr);

					utils.sheet_add_aoa(worksheet, [xlsxHeaders]);
					worksheet['!cols'] = calculateXlsxHeaderWidth(arr, xlsxHeaders);

					const wb = utils.book_new();
					utils.book_append_sheet(wb, worksheet, 'Sheet1');

					writeFile(
						wb,
						`${_.upperFirst(name)} - ${new Date().toLocaleDateString().replaceAll('/', '-')}.xlsx`
					);
				}
			}
		};

		// function to calculate xlsx column width based on the header and data
		const calculateXlsxHeaderWidth = (arr, headers) => {
			const MAX_HEADER_WIDTH = 30;
			const wscols = Array(Object.keys(headers).length).fill({ width: 10 });

			headers.forEach((key, index) => {
				if (key && key.length > 0) {
					wscols[index] = {
						width: Math.min(Math.max(key.length + 1, wscols[index].width), MAX_HEADER_WIDTH),
					};
				}
			});
			arr.forEach(row => {
				Object.keys(row).forEach((key, index) => {
					if (typeof row[key] === 'number') {
						wscols[index] = { width: Math.min(Math.max(10, wscols[index].width), MAX_HEADER_WIDTH) };
					} else if (row[key] && row[key].length > 0) {
						wscols[index] = {
							width: Math.min(Math.max(row[key].length + 2, wscols[index].width), MAX_HEADER_WIDTH),
						};
					}
				});
			});

			return wscols;
		};

		// when exportHeader is updated, trigger the download
		useEffect(() => {
			if (csvLinkRef && csvLinkRef.current && exportHeader.length > 0) {
				csvLinkRef.current.link.click();
			}
		}, [exportHeader]);

		// Set empty message for each kind of grid
		useEffect(() => {
			if (name === 'teaching-folder') {
				setEmptyMessage(t('No study saved'));
			}
		}, [name]);

		const columnHelper = createColumnHelper();
		const cols = useMemo(
			() =>
				columns.map(c => {
					const labelWidth = getTextWidth(c.label, 'bold 14px Roboto');
					const width = labelWidth > 200 ? labelWidth : 200;

					return columnHelper.accessor(c.name, {
						cell: c.cell ? c.cell : info => info.getValue() || '',
						header: c.header || c.label || '',
						id: c.name,
						// https://tanstack.com/table/v8/docs/guide/column-defs#accessor-functions
						accessorFn: c.accessorFn,
						draggable: typeof c.meta?.draggable === 'undefined' ? true : c.meta?.draggable,
						visible: c.meta?.visible !== 'excluded',
						sortingFn: c.sortingFn,
						options: c.options,
						size: columnSizing[c.name] || c.size || defaultColumnWidth || width,
						minSize: c.minSize || c.size || defaultColumnWidth || 104, // 104 is the min size so that the sort button still looks ok
						maxSize: c.maxSize || 3000, // arbitrary size
					});
				}),
			[columns, columnSizing, defaultColumnWidth]
		);

		// Setting initial order for columns
		const [columnOrder, setColumnOrder] = useState([]);

		// When columns (order, addition, or deletion ) changes
		useEffect(() => {
			setColumnOrder(cols.map(column => column.id));
		}, [columns]);

		const {
			data: tableData,
			isFetchingNextPage,
			fetchNextPage,
			isFetching,
			isLoading,
			refetch,
		} = useInfiniteQuery(
			['table-data', name, ...[cacheKey]],
			async ({ pageParam = 1 }) => {
				const fetchedData = onLoadDataRef
					? await onLoadDataRef.current(pageParam, pageSize)
					: await onLoadData(pageParam, pageSize);
				setHasMoreData(fetchedData?.length == pageSize);
				if (setEnableDownloadButton) {
					setEnableDownloadButton(true);
				}

				return fetchedData;
			},
			{
				getNextPageParam: (lastPage, allPages) => (lastPage?.length > 0 ? allPages.length + 1 : undefined),
				cacheTime: disableDataCache ? 0 : undefined,
				keepPreviousData: true,
				refetchOnWindowFocus: false,
			}
		);

		const { command, setCommand, setReceivedTranscription } = useRecognitionContext();

		//we must flatten the array of arrays from the useInfiniteQuery hook
		const flatData = useMemo(() => {
			let result = tableData?.pages?.flatMap(page => page) ?? [];

			const _result = clearDuplicates(result, stickyStack.current || []);
			result = [...(stickyStack.current || []), ..._result];

			return result;
		}, [tableData, forceRefresh]);

		useEffect(() => {
			const wasItemRemoved = hasAtLeastOneNonIdenticalItem(stickyStack.current || [], selectedResources);

			if (wasItemRemoved) {
				stickyStack.current = findIdenticalItems(selectedResources, stickyStack.current || []);
				setForceRefresh(val => !val);
			} else if (shouldUpdateAfterFiltersChanged) {
				stickyStack.current = [...selectedResources];
				onUpdateAfterFiltersChanged(false);
			}
		}, [shouldUpdateAfterFiltersChanged, selectedResources]);

		const selectedResourcesRowsIds = useMemo(() => {
			const duplicateIndexes = [];

			flatData?.forEach((item1, index1) => {
				selectedResources?.forEach(item2 => {
					if (item1.studyID === item2.studyID) {
						duplicateIndexes.push(index1);
					}
				});
			});

			return duplicateIndexes;
		}, [flatData, selectedResources]);

		// Set total # of rows
		setTotalRecords?.(flatData.length);

		const getRowIndexOf = (colName, value) => _.findIndex(flatData, el => el[colName] === value);

		const getRowData = (colName, value) => _.find(flatData, el => el[colName] === value);

		const updateData = (rowIndex, colName, value, extensionUrl) => {
			const page = Math.floor(rowIndex / pageSize);
			const pageRowIdx = rowIndex % pageSize;

			const record = tableData.pages[page][pageRowIdx];

			// If extensionUrl is provided, we need to update the extension. This is to fix the
			// issue where inline updating Priority but the previous value is still displayed before the row data is refreshed
			if (extensionUrl) {
				const index = record?.extension?.findIndex(({ url }) => url === extensionUrl);
				if (
					index > -1 &&
					typeof value === 'object' &&
					record.extension[index]?.valueCoding?.display &&
					record.extension[index]?.valueCoding?.code
				) {
					record.extension[index].valueCoding.display = value?.display;
					record.extension[index].valueCoding.code = value?.code;
				}
			}

			// colName can be a complex path like extension[0].valueCoding.display
			_.set(record, colName, value);

			// not a big fan of this, but need to a mechanism to refresh once
			setForceRefresh(val => !val);
		};

		const insertUpdateRow = syncObject => {
			const rowIndex = getRowIndexOf('id', syncObject.id);

			// Update existing row by new rowData
			if (rowIndex > -1) {
				let page;
				let pageRowIdx;
				// since the fist page contains dynamic length of data, we need to handle it seperatly
				if (rowIndex <= tableData.pages[0].length) {
					page = 0;
					pageRowIdx = rowIndex;
				} else {
					const rowIndexWithoutFirstPage = rowIndex - tableData.pages[0].length;
					page = Math.floor(rowIndexWithoutFirstPage / pageSize) + 1;
					pageRowIdx = rowIndexWithoutFirstPage % pageSize;
				}

				if (syncObject.data) {
					tableData.pages[page][pageRowIdx] = syncObject.data;
				}
				// remove the study if it's no longer need to be displayed
				else {
					tableData?.pages[page]?.splice(pageRowIdx, 1);
				}

				// not a big fan of this, but need to a mechanism to refresh once
				setForceRefresh(val => !val);
			}
			// Insert the new data at the first index of the table
			else if (syncObject.data && tableData?.pages[0] && syncObject.isAdding) {
				tableData.pages[0].unshift(syncObject.data);

				setForceRefresh(val => !val);
			}
		};

		const getPageInfo = rowIndex => {
			let page;
			let pageRowIdx;

			// since the fist page contains dynamic length of data, we need to handle it seperatly
			if (rowIndex <= tableData.pages[0].length) {
				page = 0;
				pageRowIdx = rowIndex;
			} else {
				const rowIndexWithoutFirstPage = rowIndex - tableData.pages[0].length;
				page = Math.floor(rowIndexWithoutFirstPage / pageSize) + 1;
				pageRowIdx = rowIndexWithoutFirstPage % pageSize;
			}

			return { page, pageRowIdx };
		};

		const batchInsertUpdateRows = (syncArray, worklistFeatureFlags) => {
			let refresh = false;

			syncArray.forEach(syncObject => {
				const rowIndex = getRowIndexOf('id', syncObject.id);
				// Update existing row by new rowData
				if (rowIndex > -1) {
					const { page, pageRowIdx } = getPageInfo(rowIndex);

					if (syncObject.data && !syncObject.isDeleting) {
						tableData.pages[page][pageRowIdx] = syncObject.data;
					}
					// remove the study if it's no longer need to be displayed
					else {
						tableData.pages[page]?.splice(pageRowIdx, 1);
					}

					refresh = true;
				}
				// Insert the new data at the first index of the table
				else if (syncObject.data && tableData?.pages[0] && syncObject.isAdding) {
					tableData.pages[0].unshift(syncObject.data);
					refresh = true;
				}
			});

			// // not a big fan of this, but need to a mechanism to refresh once
			if (refresh) {
				setForceRefresh(val => !val);
			}
		};

		const removeRows = syncArray => {
			let shouldUpdate = false;

			for (const syncObject of syncArray) {
				const rowIndex = getRowIndexOf('id', syncObject.id);
				if (rowIndex <= -1) {
					continue;
				}

				const { page, pageRowIdx } = getPageInfo(rowIndex);
				tableData.pages[page].splice(pageRowIdx, 1);
				shouldUpdate = true;
			}

			if (shouldUpdate) {
				setForceRefresh(val => !val);
			}
		};

		useImperativeHandle(
			ref,
			() => ({
				updateData,
				getRowIndexOf,
				getRowData,
				insertUpdateRow,
				batchInsertUpdateRows,
				removeRows,
				handleExport,
			}),
			[tableData, flatData]
		);

		// a mechanism to invalidate the entire grid and re-fetch
		const refetchData = () => {
			refetch();
		};

		//called on scroll and possibly on mount to fetch more data as the user scrolls and reaches bottom of table
		const fetchMoreOnBottomReached = useCallback(
			containerRefElement => {
				if (containerRefElement) {
					const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
					//once the user has scrolled within 300px of the bottom of the table, fetch more data if there is any
					if (scrollHeight - scrollTop - clientHeight < 300 && !isFetching && hasMoreData) {
						fetchNextPage();
					}
				}
			},
			[isFetching, hasMoreData]
		);

		//a check on mount and after a fetch to see if the table is already scrolled to the bottom and immediately needs to fetch more data
		useEffect(() => {
			enableDataGridOptimization
				? fetchMoreOnBottomReached(vRowRef.current)
				: fetchMoreOnBottomReached(tableContainerRef.current);
		}, [fetchMoreOnBottomReached]);

		// Function to updtate the column width in the state
		const onColumnWidthChange = useCallback(
			(columnId, widthValue) => {
				const newColumnSizing = columnSizing ? { ...columnSizing } : {};

				newColumnSizing[columnId] = widthValue;
				setColumnSizing(newColumnSizing);
			},
			[columnSizing, setColumnSizing]
		);

		// onChange updates column size immediately, onEnd updates column size after
		// mouse release
		const columnResizeMode = 'onChange';
		const table = useReactTable({
			data: flatData,
			columns: cols,
			columnResizeMode,
			state: {
				columnOrder,
			},
			onColumnOrderChange: setColumnOrder,
			getCoreRowModel: getCoreRowModel(),
			meta: {
				updateData,
				refetchData,
				insertUpdateRow,
				batchInsertUpdateRows,
				removeRows,
			},
			debugTable: true,
		});

		const headerGroups = table.getHeaderGroups();

		useEffect(() => {
			if (command?.type === 'open study') {
				const record = flatData[0];
				const patientID = record?.patientID;
				const internalPatientId = record?.subject?.id;
				let studyInstanceUid = record?.identifier?.find(
					recordItem => recordItem.system === 'urn:dicom:uid'
				)?.value;
				studyInstanceUid = studyInstanceUid?.replace('urn:oid:', '');
				const issuerOfPatientId = record?.extension?.find(
					ext => ext.url == fhirExtensionUrls.organization.issuer
				)?.valueReference?.display;
				const internalStudyId = record?.id;
				const orderId = record?.basedOn?.[0]?.id;
				const internalManagingOrganizationID = record?.internalManagingOrganizationID;
				const { referringFacilityId } = record;
				const { documentViewerNavigateURL } = getViewerURLs({
					patientID,
					internalPatientId,
					orderId,
					internalStudyId,
					internalManagingOrganizationID,
					issuerOfPatientId,
					referringFacilityId,
					studyInstanceUid,
				});

				goTo.any(documentViewerNavigateURL);
				setCommand(null);
				setReceivedTranscription('[...opened]');
			}
		}, [command]);

		useEffect(() => {
			if (canResizeColumns) {
				// get the last visible header
				const flatHeaders = headerGroups?.map(headerGroup => headerGroup.headers)?.flat();
				let visibleColHeaders = flatHeaders?.filter((header, index) => cols[index].visible);

				// Calculate and set the width of the empty spacing column that is
				// placed at the very end of the grid.
				// The spacing column should expand to fill in the remaining space if the
				// width of the other columns + the minimum width of the spacing column
				// are not enough to fill it out. This is done so that the row
				// animations and effects always extend fully across the grid.
				let customLastWidth = 0;
				if (Array.isArray(visibleColHeaders) && visibleColHeaders.length > 0) {
					setLastColId(visibleColHeaders[visibleColHeaders.length - 1].id);

					// Exclude the width of first element, its width will always be 24px
					// as defined in DataGridColumnHeader
					// Exclude the last element, it is the spacingCol
					visibleColHeaders = visibleColHeaders?.slice(1, visibleColHeaders.length - 1);
					// each cell except the last cell has 20 horizontal padding
					const cellPadding = 20;
					let totalVisibleColumnWidth = 24 + cellPadding;
					visibleColHeaders.forEach(
						header => (totalVisibleColumnWidth = totalVisibleColumnWidth + header.getSize() + cellPadding)
					);
					// Add padding for the excluded last col
					totalVisibleColumnWidth += 20;

					// subtract 8 pixels from tableMeasurements to account for scrollbar
					if (tableMeasurements.width - 8 > totalVisibleColumnWidth) {
						const diff = tableMeasurements.width - 8 - totalVisibleColumnWidth;
						customLastWidth += diff;
					}
				}
				setLastColWidth(customLastWidth);

				// Check if the grid has finished rendering
				if (headerGroups?.length > 0 && headerGroups[0]?.headers?.length > 0) {
					setIsRendered(true);
				}
			}
		}, [tableMeasurements.width, headerGroups, canResizeColumns]);

		// Virtualizing
		const { rows } = table.getRowModel();

		// useVirtualizer is tanstack/react-virtual libray that takes care of the virtualization
		const rowVirtualizer = enableDataGridOptimization
			? useVirtualizer({
					count: rows.length,
					getScrollElement: useCallback(() => vRowRef.current, []),
					estimateSize: () => 50, // estimate size of each row, smaller number means more rows are rendered
					overscan: 15, // number of rows to render above and below the visible area, bigger means slower scrolling but less flickering
					getItemKey: useCallback(index => rows[index]?.id ?? index, [rows]), // use callback this reducer re-rendering of rows
			  })
			: { getVirtualItems: () => [] };

		const items = rowVirtualizer.getVirtualItems();

		// before and after are needed for padding the top and bottom of the virtulualized table to make header sticky
		const [before, after] =
			items.length > 0
				? [
						notUndefined(items[0]).start - rowVirtualizer.options.scrollMargin,
						rowVirtualizer.getTotalSize() - notUndefined(items[items.length - 1]).end,
				  ]
				: [0, 0];

		return (
			<DndProvider backend={HTML5Backend}>
				{isLoading || isFetchingNextPage ? (
					<LinearProgress
						sx={{
							position: tableHeaderRef?.current ? 'absolute' : 'relative',
							top: tableHeaderRef?.current?.offsetTop + tableHeaderRef?.current?.offsetHeight || 'unset',
							zIndex: '2',
							width: tableContainerRef?.current?.offsetWidth,
						}}
					/>
				) : (
					''
				)}
				<CSVLink
					ref={csvLinkRef}
					data={exportData}
					filename={`${name?.charAt(0)?.toUpperCase() + name?.slice(1)} - ${new Date()
						.toLocaleDateString()
						.replaceAll('/', '-')}.csv`}
					headers={exportHeader}
				/>
				<TableContainer
					ref={mergeRefs([tableContainerRef, tableMeasurementsRef])}
					className="datagrid"
					data-testid="data-grid-table-container"
					sx={{
						width: 'unset',
						height,
					}}
					onScroll={e => {
						!enableDataGridOptimization && fetchMoreOnBottomReached(e.target);

						// Save scroll position on scroll
						onScrollChange({
							scrollTop: e.target.scrollTop,
							scrollLeft: e.target.scrollLeft,
						});
					}}
				>
					{enableDataGridOptimization ? (
						<div
							ref={vRowRef} // useVirtualizer uses this ref to calculate scrolling and what items to render
							data-testid="data-grid-table-vrow"
							style={{ overflow: 'auto', height: '100%', overflowAnchor: 'none' }}
							onScroll={e => {
								fetchMoreOnBottomReached(e.target);

								// Save scroll position on scroll
								onScrollChange({
									scrollTop: e.target.scrollTop,
									scrollLeft: e.target.scrollLeft,
								});
							}}
						>
							<Table
								stickyHeader
								data-cy="study-status-table"
								{...(canResizeColumns || forceFixed ? { sx: { tableLayout: 'fixed' } } : null)}
							>
								<TableHead
									ref={tableHeaderRef}
									data-testId={`${name}-data-grid-table-header`}
									sx={{
										position: 'sticky',
										top: 0,
										zIndex: 1,
										...subRowSX,
									}}
								>
									{headerGroups.map(headerGroup => (
										<TableRow
											key={headerGroup.id}
											sx={{
												'& .MuiTableCell-root': {
													color: 'rgba(255, 255, 255, 0.87)',
													backgroundColor: 'rsSecondary.medium',
												},
											}}
										>
											{headerGroup.headers
												.map(
													(header, index) =>
														cols[index].visible && (
															<DataHeader
																key={header.id}
																canResizeColumns={canResizeColumns}
																cellSx={{
																	':nth-last-of-type(2)': {
																		'.resizer': {
																			opacity: secondLastChildResizerOpacity,
																		},
																	},
																}}
																cols={cols}
																columnResizeMode={columnResizeMode}
																draggableTable={table}
																enableColumnDnd={
																	cols[index].draggable && enableColumnDnd
																}
																enableDataGridOptimization={enableDataGridOptimization}
																flexRender={flexRender}
																header={header}
																index={index}
																lastColWidth={lastColWidth}
																rows={
																	gridCustomHeader && header.id.includes('flagCol')
																		? rows
																		: []
																}
																setCurrWorklistColumns={setCurrWorklistColumns}
																onColumnWidthChange={onColumnWidthChange}
																onMouseOut={
																	lastColId === header?.id
																		? () => setSecondLastChildResizerOpacity(0)
																		: null
																}
																onMouseOver={
																	lastColId === header?.id
																		? () => setSecondLastChildResizerOpacity(1)
																		: null
																}
															/>
														)
												)
												?.filter(component => component)}
										</TableRow>
									))}
								</TableHead>
								<TableBody>
									{before > 0 && ( // padding for the top of the table, needed to make table header sticky
										<tr>
											<td colSpan={cols.length} style={{ height: before }} />
										</tr>
									)}
									{items.map(virtualRow => {
										const row = rows[virtualRow.index];
										const { index } = virtualRow;
										return (
											<DataRow
												key={`${row.id}-${index}`}
												canResizeColumns={canResizeColumns}
												cellSx={cellSx}
												cellsPadding={cellsPadding}
												columnOrder={columnOrder}
												enableDataGridOptimization={enableDataGridOptimization}
												enableRowDnd={enableRowDnd}
												flexRender={flexRender}
												isExpanded={index === expandedRowIndex}
												rowIndex={index}
												selectedRow={[selectedRow, ...selectedResourcesRowsIds]?.includes(
													Number(row.id)
												)}
												size={virtualRow.size}
												subRow={subRow}
												tableRow={row}
												visitedWorklist={visitedWorklist}
												onRowClick={onRowClick}
											/>
										);
									})}
									{after > 0 && ( // padding for the bottom of the table, needed to make table header sticky
										<tr>
											<td colSpan={cols.length} style={{ height: after }} />
										</tr>
									)}
									{rows?.length === 0 && emptyMessage && (
										<TableRow>
											<TableCell colSpan={12} sx={{ textAlign: 'center' }}>
												{emptyMessage}
											</TableCell>
										</TableRow>
									)}
								</TableBody>
								{footer && (
									<TableFooter>
										<TableCell colSpan={12} sx={{ padding: 0 }}>
											{footer}
										</TableCell>
									</TableFooter>
								)}
							</Table>
						</div>
					) : (
						<Table
							stickyHeader
							data-cy="study-status-table"
							{...(canResizeColumns || forceFixed ? { sx: { tableLayout: 'fixed' } } : null)}
						>
							<TableHead
								ref={tableHeaderRef}
								data-testId={`${name}-data-grid-table-header`}
								sx={{
									position: 'relative',
									zIndex: '1',
									...subRowSX,
								}}
							>
								{headerGroups.map(headerGroup => (
									<TableRow
										key={headerGroup.id}
										sx={{
											'& .MuiTableCell-root': {
												color: 'rgba(255, 255, 255, 0.87)',
												backgroundColor: 'rsSecondary.medium',
											},
										}}
									>
										{headerGroup.headers
											.map(
												(header, index) =>
													cols[index].visible && (
														<DataHeader
															key={header.id}
															canResizeColumns={canResizeColumns}
															cellSx={{
																':nth-last-of-type(2)': {
																	'.resizer': {
																		opacity: secondLastChildResizerOpacity,
																	},
																},
															}}
															cols={cols}
															columnResizeMode={columnResizeMode}
															draggableTable={table}
															enableColumnDnd={cols[index].draggable && enableColumnDnd}
															enableDataGridOptimization={enableDataGridOptimization}
															flexRender={flexRender}
															header={header}
															index={index}
															lastColWidth={lastColWidth}
															rows={
																gridCustomHeader && header.id.includes('flagCol')
																	? rows
																	: []
															}
															setCurrWorklistColumns={setCurrWorklistColumns}
															onColumnWidthChange={onColumnWidthChange}
															onMouseOut={
																lastColId === header?.id
																	? () => setSecondLastChildResizerOpacity(0)
																	: null
															}
															onMouseOver={
																lastColId === header?.id
																	? () => setSecondLastChildResizerOpacity(1)
																	: null
															}
														/>
													)
											)
											?.filter(component => component)}
									</TableRow>
								))}
							</TableHead>
							<TableBody>
								{rows?.map((row, index) => {
									const selectedRows = [selectedRow, ...selectedResourcesRowsIds];
									const isRowSelected = selectedRows?.includes(Number(row.id));

									return (
										<DataRow
											key={`${row.id}-${index}`}
											canResizeColumns={canResizeColumns}
											cellSx={cellSx}
											cellsPadding={cellsPadding}
											enableDataGridOptimization={enableDataGridOptimization}
											enableRowDnd={enableRowDnd}
											flexRender={flexRender}
											isExpanded={index === expandedRowIndex}
											rowIndex={index}
											selectedRow={isRowSelected}
											subRow={subRow}
											tableRow={row}
											visitedWorklist={visitedWorklist}
											onRowClick={onRowClick}
										/>
									);
								})}
								{rows?.length === 0 && emptyMessage && (
									<TableRow>
										<TableCell colSpan={12} sx={{ textAlign: 'center' }}>
											{emptyMessage}
										</TableCell>
									</TableRow>
								)}
							</TableBody>
							{footer && (
								<TableFooter>
									<TableCell colSpan={12} sx={{ padding: 0 }}>
										{footer}
									</TableCell>
								</TableFooter>
							)}
						</Table>
					)}
				</TableContainer>
			</DndProvider>
		);
	}
);

const DataRow = ({ enableDataGridOptimization, ...props }) => {
	if (enableDataGridOptimization) {
		return <MemoizedDataGridRow {...props} />;
	}

	return <DataGridRow {...props} />;
};

const DataHeader = ({ enableDataGridOptimization, ...props }) => {
	if (enableDataGridOptimization) {
		return <MemoizedDataGridColumnHeaderV2 {...props} />;
	}

	return <DataGridColumnHeaderV2 {...props} />;
};

DataGrid.propTypes = {
	/**
	 * Name of the table, which will be used as a caching key so please ensure that it is unique system wide.
	 */
	name: PropTypes.string.isRequired,
	/**
	 * Column definition for the grid
	 */
	columns: PropTypes.array,
	/**
	 * Another potential parameter to be used as part of the queryKey in the useInfiniteQuery.
	 */
	cacheKey: PropTypes.string,
	/**
	 * Data to be displayed in the grid.
	 */
	data: PropTypes.array,
	/**
	 * Max records to fetch per page
	 */
	pageSize: PropTypes.number,
	/**
	 * Height of the datagrid
	 */
	height: PropTypes.string,
	/**
	 * Indicates whether data is in the process of loading.
	 */
	isLoading: PropTypes.bool,
	/**
	 * Determines if the columns can be resized or not.
	 */
	canResizeColumns: PropTypes.bool,
	/**
	 * Optional - lifted state object with column ids as keys and column widths (numbers) as values. This should be passed in if canResizeColumns is true
	 */
	columnSizing: PropTypes.object,
	/**
	 * Optional - Setter function for columnSizing. Should be passed in columnSizing
	 * is passed in
	 */
	setColumnSizing: PropTypes.func,
	/**
	 * Function to call to get the next page of data.
	 */
	onLoadData: PropTypes.func,
	/**
	 * Handler for when a row is clicked. Passes back the row record for processing.
	 */
	onRowClick: PropTypes.func,
	/**
	 * Determines if row DnD shall be enabled
	 */
	enableRowDnd: PropTypes.bool,
	/**
	 * Determines if column DnD shall be enabled
	 */
	enableColumnDnd: PropTypes.bool,
	/**
	 * Provides the selected row index to the grid
	 */
	selectedRow: PropTypes.number,
	/**
	 * Provides the selected resources to be potentially stick to the top of grid
	 */
	selectedResources: PropTypes.array,
	/**
	 * Flag to watch filters changes to manage selected items at the top of the table
	 */
	shouldUpdateAfterFiltersChanged: PropTypes.bool,
	/**
	 * Function to let the parent layer know that the changes triggered by filters changes were applied
	 */
	onUpdateAfterFiltersChanged: PropTypes.func,
	/**
	 * css properties to apply on tabel cell
	 */
	cellSx: PropTypes.object,
	/**
	 * Determines if we want to force the table to be fixed, which sets the tableLayout to "fixed".
	 * When set to 'fixed', the table layout algorithm assigns a fixed width to each column based on the width of the first row of cells,
	 * and does not adjust the column widths based on the content of the cells.
	 */
	forceFixed: PropTypes.bool,
	/**
	 * function to enable or disable download button, will enable download button after data is loaded
	 */
	setEnableDownloadButton: PropTypes.func,
	/**
	 * Function to set the current the scroll position
	 */
	onScrollChange: PropTypes.func,
	/**
	 * The scroll position
	 */
	scrollPosition: PropTypes.shape({
		scrollTop: PropTypes.number,
		scrollLeft: PropTypes.number,
	}),
};

DataGrid.defaultProps = {
	pageSize: 50,
	canResizeColumns: false,
	isLoading: false,
	enableRowDnd: true,
	enableColumnDnd: true,
	forceFixed: false,
	onScrollChange: () => {},
	scrollPosition: {
		scrollTop: 0,
		scrollLeft: 0,
	},
	columnSizing: {},
};

DataGrid.displayName = 'DataGrid';

export const MemoizedDataGrid = memo(DataGrid);

export default DataGrid;
