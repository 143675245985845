import { logDebug } from '@rs-core/index';
import { compressObjectToArrayBuffer, decompressArrayBufferToObject } from '@rs-core/utils';

const sharedWorker = (key, api, { initialize = false, wonIvBroadcastMsgCompress = false } = {}) => {
	const worker = new SharedWorker('sharedworker.js');
	let externalUpdate = false;
	let timestamp = 0;

	const _postMessage = message => {
		worker?.port?.postMessage(message);
	};

	const cleanup = api.subscribe(
		state => state[key],
		state => {
			if (!externalUpdate) {
				timestamp = Date.now();
				let stateData = state;

				logDebug('useSharedWorker', `_postMessage - KEY: ${key} - Start posting setState`, {
					timestamp: Date.now(),
				});

				if (wonIvBroadcastMsgCompress) {
					// compress message before sending
					stateData = compressObjectToArrayBuffer(state);
					logDebug('useSharedWorker', `_postMessage - KEY: ${key} - after compressed`, {
						timestamp: Date.now(),
					});
				}

				_postMessage({ type: 'setState', key, timestamp, state: stateData });
			}
			externalUpdate = false;
		}
	);

	worker.port.onmessage = evt => {
		const message = evt.data;
		let stateData = message.state;
		logDebug('useSharedWorker', `worker.port.onmessage - KEY: ${key} - Start`, {
			timestamp: Date.now(),
		});

		if (wonIvBroadcastMsgCompress && stateData) {
			// decompress message after receiving
			stateData = decompressArrayBufferToObject(stateData);
			logDebug('useSharedWorker', `worker.port.onmessage - KEY: ${key} - after decompress`, {
				timestamp: Date.now(),
			});
		}

		if (message.type === 'stateUpdate') {
			if (
				key !== message.key ||
				!message.timestamp ||
				message.timestamp <= timestamp ||
				!stateData ||
				api.getState()[key] === stateData
			) {
				return;
			}

			externalUpdate = true;
			timestamp = message.timestamp;
			api.setState({ [key]: stateData });
		}
	};

	const init = () => _postMessage({ type: 'init', key });
	const sync = () => _postMessage({ type: 'getState', key });

	const resetState = () => {
		_postMessage({ type: 'resetState', key });
	};

	const unshare = () => {
		cleanup();
		resetState();
		worker.port.close();
	};

	if (initialize) {
		init();
		sync();
	}

	return [sync, unshare];
};

export { sharedWorker };
